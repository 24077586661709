import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateDeviceForm } from "../../forms"
import { DeviceFragment } from "../../graphql"

export type UpdateDeviceProps = {
	device: DeviceFragment
}

export const UpdateDevice: React.FC<UpdateDeviceProps> = ({ device }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateDeviceForm device={device} />
		</VStack>
	)
}
