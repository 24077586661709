import { Box, Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { DepartmentsByKeywordQueryVariables, useDepartmentsByKeywordQuery } from "../../graphql"

export type DepartmentsSelectorProps = {
	onUpdate: (departmentIds: string[]) => void
	value: string[]
}

export const DepartmentsSelector: React.FC<DepartmentsSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<DepartmentsByKeywordQueryVariables>({ keyword: "", pagination: { limit: 20, page: 1 } })

	const nextPage = () => {
		if (data?.departmentsByKeyword.hasNextPage && !fetching) {
			setVariables((prev) => ({
				...prev,
				pagination: {
					...prev.pagination,
					page: (prev.pagination.page || 0) + 1,
				},
			}))
		}
	}
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			nextPage()
		}
	}, [inViewport])

	const [{ data, fetching, error }] = useDepartmentsByKeywordQuery({ variables })

	const [keyword, setKeyword] = useState("")

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				keyword,
				pagination: { ...prev.pagination, page: 1 },
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (departmentId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, departmentId])
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(departmentId), 1)
			onUpdate(_value)
		}
	}

	const isDepartmentSelected = useCallback((departmentId: string) => value.includes(departmentId), [value])

	const isAllSelected = useMemo(() => data?.departmentsByKeyword.departments.map((r) => r._id).every((rid) => value.includes(rid)), [data, value])
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
			</HStack>
			{fetching && !data?.departmentsByKeyword.departments.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading departments</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.departmentsByKeyword.departments.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any Department.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isDisabled={data.departmentsByKeyword.hasNextPage}
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked ? onUpdate(data.departmentsByKeyword.departments.map((r) => r._id)) : onUpdate([])
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>Department</Th>
								<Th>Type</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.departmentsByKeyword.departments.map((department) => (
								<Tr key={department._id}>
									<Td>
										<Checkbox isChecked={isDepartmentSelected(department._id)} onChange={(e) => handleChange(department._id, e.target.checked)} />
									</Td>
									<Td>{department.label.name}</Td>
								</Tr>
							))}
							<Box w="full" h="2" ref={ref as any} />
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more departments</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
