import { Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React from "react"
import { ExtendedAlertFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type AlertDetailsProps = {
	alert: ExtendedAlertFragment
}

export const AlertDetails: React.FC<AlertDetailsProps> = ({ alert }) => {
	return (
		<Card title="Details">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(alert.type)} />
				<Stat label="Created" value={format(new Date(alert.createdAt), "MMM dd,yyyy p")} />
			</Flex>
		</Card>
	)
}
