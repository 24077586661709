import { Button, Flex, HStack, Link } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedAlertFlowFragment, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type AlertFlowDetailsProps = {
	alertFlow: ExtendedAlertFlowFragment
}

export const AlertFlowDetails: React.FC<AlertFlowDetailsProps> = ({ alertFlow }) => {
	const [{ data }] = useMeQuery()

	const canUpdate = useMemo(() => checkForPermissions(data?.me, "alerts/update-alert-flow")[0], [data])

	return (
		<Card
			title="Details"
			action={
				canUpdate && (
					<Button as={NavLink} to={`/alerts/flows/${alertFlow._id}/update`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(alertFlow.type)} />
				<Stat
					label="Targets"
					value={
						<HStack flexWrap="wrap" justify="center" maxW="xs">
							{alertFlow.targetGroups.map((t, i) => (
								<Link key={t._id} as={NavLink} to={`/alerts/targetGroups/${t._id}`}>
									{t.label.name}
									{i < (alertFlow.targetGroups?.length || 0) - 1 && ","}
								</Link>
							))}
						</HStack>
					}
				/>
				<Stat label="Created" value={format(new Date(alertFlow.createdAt), "MMM dd,yyyy p")} />
			</Flex>
		</Card>
	)
}
