import { VStack } from "@chakra-ui/react"
import React from "react"
import { CreateBuildingForm } from "../../forms"

export const CreateBuilding: React.FC = () => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<CreateBuildingForm />
		</VStack>
	)
}
