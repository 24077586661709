import { Flex, ListItem, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React from "react"
import { UserAttendanceRecordFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Stat } from "../common"

export type UserAttendanceListItemProps = {
	userAttendanceRecord: UserAttendanceRecordFragment
}

export const UserAttendanceListItem: React.FC<UserAttendanceListItemProps> = ({ userAttendanceRecord }) => {
	return (
		<ListItem>
			<VStack w="full" align="flex-start" p="2">
				<Flex w="full" justify="space-between" align="center">
					<Stat label="Since" value={format(new Date(userAttendanceRecord.startAt), "MMM dd, yyyy p")} />
					<Stat label="Until" value={format(new Date(userAttendanceRecord.endAt), "MMM dd, yyyy p")} />
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(userAttendanceRecord.endAt), new Date(userAttendanceRecord.startAt)))} />
				</Flex>
			</VStack>
		</ListItem>
	)
}
