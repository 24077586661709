import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { DeviceFragment } from "../../graphql"
import { DeviceListItem } from "./DeviceListItem"

export type DevicesListProps = {
	devices: DeviceFragment[]
	zoneId?: string
	loadMore?: () => void
}

export const DevicesList: React.FC<DevicesListProps> = ({ devices, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{devices.map((device) => (
				<Fragment key={device._id}>
					<DeviceListItem key={device._id} device={device} />
					<Divider />
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
