import { Flex, Link } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedAssetTagFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type AssetTagDetailsProps = {
	assetTag: ExtendedAssetTagFragment
}

export const AssetTagDetails: React.FC<AssetTagDetailsProps> = ({ assetTag }) => {
	return (
		<Card title="Details">
			<Flex flex="1" justify="center" align="center" flexWrap="wrap">
				{assetTag.asset && (
					<Stat
						label="Asset"
						value={
							<Link as={NavLink} to={`/assets/${assetTag.asset._id}`}>
								{assetTag.asset.name}
							</Link>
						}
					/>
				)}
				{assetTag.user && (
					<Stat
						label="User"
						value={
							<Link as={NavLink} to={`/users/${assetTag.user._id}`}>
								{assetTag.user.name}
							</Link>
						}
					/>
				)}
				<Stat label="UID" value={assetTag.tagHexId} />
				<Stat label="Status" value={assetTag.status} />
				<Stat label="Battery Status" value={assetTag.batteryStatus} />
			</Flex>
		</Card>
	)
}
