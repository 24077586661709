import { Button, Flex, HStack, Link } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserRoleGroupFragment, useMeQuery, UserRoleTypes } from "../../graphql"
import { checkForSomePermissions } from "../../utils"
import { Card, Stat } from "../common"

export type UserRoleGroupDetailsProps = {
	userRoleGroup: ExtendedUserRoleGroupFragment
}

export const UserRoleGroupDetails: React.FC<UserRoleGroupDetailsProps> = ({ userRoleGroup }) => {
	const [{ data: meData }] = useMeQuery()

	const canUpdate = useMemo(() => {
		if (userRoleGroup.type === UserRoleTypes.ShortTerm) {
			return checkForSomePermissions(meData?.me, "user-roles/update-short-term-role-group")[0]
		} else {
			return checkForSomePermissions(meData?.me, "user-roles/update-working-role-group")[0]
		}
	}, [meData])

	return (
		<Card
			title="Details"
			action={
				canUpdate && (
					<Button as={NavLink} to={`/userRoleGroups/${userRoleGroup._id}/update`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(userRoleGroup.type)} />
				<Stat
					label="Department"
					value={
						<Link as={NavLink} to={`/departments/${userRoleGroup.departmentId}`}>
							{userRoleGroup.department.label.name}
						</Link>
					}
				/>
				{userRoleGroup.allowedZoneGroups?.length ? (
					<Stat
						label="Allowed Zone Groups"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{userRoleGroup.allowedZoneGroups.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/zones/groups/${o._id}`}>
										{o.label.name}
										{i < (userRoleGroup.allowedZoneGroups?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				) : (
					<></>
				)}
				{userRoleGroup.allowedZones?.length ? (
					<Stat
						label="Allowed Zones"
						value={
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{userRoleGroup.allowedZones.map((o, i) => (
									<Link key={o._id} as={NavLink} to={`/zones/${o._id}`}>
										{o.label.name}
										{i < (userRoleGroup.allowedZones?.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						}
					/>
				) : (
					<></>
				)}
			</Flex>
		</Card>
	)
}
