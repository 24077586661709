import { Center, Flex, Icon, Link, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds } from "date-fns"
import React from "react"
import { XCircle } from "react-feather"
import { NavLink } from "react-router-dom"
import { AssetLocationRecordStatus, useAssetCurrentLocationRecordQuery } from "../../graphql"
import { getDurationInWords, transformDateTime } from "../../utils"
import { Card, LiveIndicator, Stat } from "../common"

export type AssetCurrentLocationProps = {
	assetId: string
}

export const AssetCurrentLocation: React.FC<AssetCurrentLocationProps> = ({ assetId }) => {
	const [{ data, fetching, error }] = useAssetCurrentLocationRecordQuery({ variables: { assetId } })

	return (
		<Card title="Current Location" action={<LiveIndicator />} alwaysShowAction>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current location</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.assetCurrentLocationRecord && data.assetCurrentLocationRecord.status !== AssetLocationRecordStatus.Final ? (
				<VStack w="full" align="flex-start" p="2">
					{data.assetCurrentLocationRecord.isRestricted && (
						<Tag colorScheme="error" variant="solid">
							Restricted <Icon as={XCircle} ml="2" />
						</Tag>
					)}
					<Flex w="full" justify="space-between" align="center">
						<Stat
							label="Zone"
							value={
								<Link as={NavLink} to={`/zones/${data.assetCurrentLocationRecord.zoneId}`}>
									{data.assetCurrentLocationRecord.zone.label.name}
								</Link>
							}
						/>
						<Stat label="Since" value={transformDateTime(new Date(data.assetCurrentLocationRecord.startAt))} />
						<Stat label="Until" value={transformDateTime(new Date(data.assetCurrentLocationRecord.endAt))} />
						<Stat label="Status" value={data.assetCurrentLocationRecord.status} />
						<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(data.assetCurrentLocationRecord.endAt), new Date(data.assetCurrentLocationRecord.startAt)))} />
					</Flex>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find current location
					</Text>
				</Center>
			)}
		</Card>
	)
}
