/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { useCreateTagMutation } from "../graphql"

type CreateTagFormValues = {
	tagDecimalId: string
}

const validationSchema = yup.object<Record<keyof CreateTagFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	tagDecimalId: yup.string().required().label("Tag ID"),
})

export const CreateTagForm: React.FC = () => {
	const initialValues: CreateTagFormValues = {
		tagDecimalId: "",
	}

	const [{ fetching }, createTag] = useCreateTagMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ tagDecimalId }: CreateTagFormValues) => {
		const { data, error } = await createTag({ tagDecimalId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createTag) {
			navigate(`/tags/${data.createTag._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateTagFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.tagDecimalId && formik.errors.tagDecimalId)}>
					<FormLabel fontWeight="bold">Tag ID</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter Tag ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("tagDecimalId")} />

					<FormErrorMessage>{formik.errors.tagDecimalId}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
