import { IntervalAction, IntervalActions, INTERVALS, IntervalState } from "./types"

export const initialIntervalState: IntervalState = INTERVALS["15 Mins."]

export const intervalReducer = (state: IntervalState = initialIntervalState, action: IntervalAction): IntervalState => {
	switch (action.type) {
		case IntervalActions.SET_INTERVAL:
			return action.payload
		default:
			return state
	}
}
