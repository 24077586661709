import { Center, chakra, Divider, Heading, HStack, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import { startCase } from "lodash"
import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { ReaderTypes, useReadersQuery } from "../../graphql"

export const ReadersStatus: React.FC = () => {
	const [{ data, fetching, error }] = useReadersQuery()

	return (
		<VStack flex="2" align="stretch" spacing={4}>
			<Heading fontSize="sm">All readers</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.readers ? (
				<VStack w="full" align="stretch">
					<HStack w="full" justify="space-between">
						<VStack align="stretch">
							<Link as={NavLink} to="/readers?status=Active">
								<Heading color="green.400">
									{data.readers.filter((r) => r.isActive).length || 0}{" "}
									<chakra.span fontSize="sm" fontWeight="semibold" color="green.400">
										active
									</chakra.span>
								</Heading>
							</Link>
							<Text fontSize="sm" color="grayscale.label">
								{Object.values(ReaderTypes).map((type, i, _) => (
									<Fragment key={type}>
										{data.readers.filter((r) => r.isActive && r.type === type).length > 0 && (
											<>
												<Link key={"Active" + type} as={NavLink} to={`/readers?status=Active&type=${type}`}>
													<chakra.span key={type} fontWeight="semibold">
														{data.readers.filter((r) => r.isActive && r.type === type).length || 0}{" "}
														<chakra.span key={type} fontWeight="normal">
															{startCase(type).toLowerCase()}
														</chakra.span>
													</chakra.span>
												</Link>
												{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
											</>
										)}
									</Fragment>
								))}
							</Text>
						</VStack>
						<Divider orientation="vertical" />
						<VStack align="stretch">
							<Link as={NavLink} to="/readers?status=Inactive">
								<Heading color="red.400">
									{data.readers.filter((r) => !r.isActive).length || 0}{" "}
									<chakra.span fontSize="sm" fontWeight="semibold" color="red.400">
										inactive
									</chakra.span>
								</Heading>
							</Link>
							<Text fontSize="sm" color="grayscale.label">
								{Object.values(ReaderTypes).map((type, i, _) => (
									<Fragment key={type}>
										{data.readers.filter((r) => !r.isActive && r.type === type).length > 0 && (
											<>
												<Link key={"Inactive" + type} as={NavLink} to={`/readers?status=Inactive&type=${type}`}>
													<chakra.span key={type} fontWeight="semibold">
														{data.readers.filter((r) => !r.isActive && r.type === type).length || 0}{" "}
														<chakra.span key={type} fontWeight="normal">
															{startCase(type).toLowerCase()}
														</chakra.span>
													</chakra.span>
												</Link>
												{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
											</>
										)}
									</Fragment>
								))}
							</Text>
						</VStack>
					</HStack>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
