/* eslint-disable @typescript-eslint/indent */
import { Flex, Heading, VStack } from "@chakra-ui/react"
import React from "react"
import { ExtendedZoneGroupFragment } from "../../graphql"
import { ZoneItem } from "./ZoneItem"

export type ZoneGroupZoneWiseUsersProps = {
	zoneGroup: ExtendedZoneGroupFragment
}

export const ZoneGroupZoneWiseUsers: React.FC<ZoneGroupZoneWiseUsersProps> = ({ zoneGroup }) => {
	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">All zones</Heading>
			<Flex w="full" flexWrap="wrap">
				{zoneGroup.zones.map((zone) => (
					<ZoneItem key={zone._id} zone={zone} />
				))}
			</Flex>
		</VStack>
	)
}
