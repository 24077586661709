import { Button, useToast, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { UserSelector } from "../components"
import { ExtendedDepartmentFragment, useAssignDepartmentHeadMutation } from "../graphql"

export type AssignDepartmentHeadFormProps = {
	department: ExtendedDepartmentFragment
}

export const AssignDepartmentHeadForm: React.FC<AssignDepartmentHeadFormProps> = ({ department }) => {
	const [{ fetching }, assignDepartmentHead] = useAssignDepartmentHeadMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const [selectedUserId, setSelectedUserId] = useState<string>()

	const onSubmit = async () => {
		if (!selectedUserId) {
			return toast({
				description: "Please select a user",
				status: "error",
			})
		}

		const { data, error } = await assignDepartmentHead({ departmentId: department._id, userId: selectedUserId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.assignDepartmentHead) {
			navigate(`/departments/${department._id}`, { replace: true })

			return
		}
	}

	return (
		<VStack w="full" align="stretch" spacing={6}>
			<UserSelector value={selectedUserId ? [selectedUserId] : []} onUpdate={([userId]) => setSelectedUserId(userId)} multiple={false} defaultDepartmentId={department._id} disableDepartmentUpdate />
			<Button colorScheme="primary" isLoading={fetching} onClick={onSubmit}>
				Assign
			</Button>
		</VStack>
	)
}
