import { Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateBuildingMutationVariables, useCreateBuildingFloorMutation } from "../graphql"

type CreateBuildingFloorFormValues = CreateBuildingMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateBuildingFloorFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
})

const initialValues: CreateBuildingFloorFormValues = {
	label: { name: "", description: "" },
}

export type CreateBuildingFloorFormProps = {
	buildingId: string
}

export const CreateBuildingFloorForm: React.FC<CreateBuildingFloorFormProps> = ({ buildingId }) => {
	const [{ fetching }, createBuilding] = useCreateBuildingFloorMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateBuildingFloorFormValues) => {
		const { data, error } = await createBuilding({ buildingId, input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createBuildingFloor) {
			navigate(`/zones/buildings/${data.createBuildingFloor._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateBuildingFloorFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
