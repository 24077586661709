import { List } from "@chakra-ui/react"
import React from "react"
import { AlertTypes, ExtendedAlertFragment } from "../../graphql"
import { Card } from "../common"
import { ReaderListItem } from "../readers/ReaderListItem"
import { RestrictedEntryListItem } from "../restrictedEntries/RestrictedEntryListItem"
import { ServiceListItem } from "../services/ServiceListItem"
import { TagListItem } from "../tags/TagListItem"
import { UserRoleListItem } from "../users"

export type AlertSourceProps = {
	alert: ExtendedAlertFragment
}

export const AlertSource: React.FC<AlertSourceProps> = ({ alert }) => {
	return (
		<Card title="Source">
			<List>
				{alert.type === AlertTypes.InactiveReader && alert.reader ? (
					<ReaderListItem reader={alert.reader} />
				) : alert.type === AlertTypes.RestrictedEntry && alert.userLocationRecord ? (
					<RestrictedEntryListItem userLocationRecord={alert.userLocationRecord} />
				) : alert.type === AlertTypes.TagBatteryLow && alert.tag ? (
					<TagListItem tag={alert.tag} />
				) : alert.type === AlertTypes.UserRoleExpiry && alert.userRole ? (
					<UserRoleListItem userRole={alert.userRole} includeUser />
				) : alert.type === AlertTypes.InactiveService && alert.service ? (
					<ServiceListItem service={alert.service} />
				) : (
					<></>
				)}
			</List>
		</Card>
	)
}
