import { Center, chakra, Heading, HStack, Link, Select, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { INTERVALS } from "../../context/Interval"
import { useActiveUserIdsInLastIntervalQuery, useTypeWiseActiveUsersInLastIntervalQuery } from "../../graphql"
import { useIntervalContext } from "../../hooks"

export const ActiveUsersCountInLastInterval: React.FC = () => {
	const [interval, setInterval] = useIntervalContext()

	const [{ data, fetching, error }] = useActiveUserIdsInLastIntervalQuery({ variables: { intervalInMinutes: interval } })

	const [{ data: typeWiseData }] = useTypeWiseActiveUsersInLastIntervalQuery({ variables: { intervalInMinutes: interval } })

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<HStack>
				<Heading fontSize="sm">Active users in last </Heading>
				<Select size="xs" w="32" fontWeight="bold" value={interval} onChange={(e) => setInterval(Number(e.target.value))}>
					{Object.entries(INTERVALS).map(([label, minutes]) => (
						<option key={minutes} value={minutes}>
							{label}
						</option>
					))}
				</Select>
			</HStack>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeUserIdsInLastInterval ? (
				<VStack align="stretch">
					<HStack>
						<VStack align="stretch">
							<Link as={NavLink} to="/users/activeInLastInterval">
								<Heading color="primary.400">{data.activeUserIdsInLastInterval.length || 0}</Heading>
							</Link>
							<Text fontSize="sm">{format(new Date(), "MMM dd, yyyy")}</Text>
						</VStack>
					</HStack>
					<Text fontSize="sm" color="grayscale.label">
						{typeWiseData?.typeWiseActiveUsersInLastInterval
							.sort((a, b) => a.label.name.charCodeAt(0) - b.label.name.charCodeAt(0))
							.map(({ typeId, label, userIds }, i, _) => (
								<Fragment key={typeId}>
									<Link key={typeId} as={NavLink} to={`/users/activeInLastInterval?userType=${typeId}`}>
										<chakra.span fontWeight="semibold">
											{userIds.length || 0} <chakra.span fontWeight="normal">{label.name}</chakra.span>
										</chakra.span>
									</Link>
									{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
								</Fragment>
							))}
					</Text>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
