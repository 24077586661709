import React from "react"
import { DashboardTypes } from "../../context/Dashboard/types"
import { ExtendedBuildingFragment } from "../../graphql"
import { useDashboardContext } from "../../hooks"
import { BuildingFloorItemAssetRecords } from "./BuildingFloorItemAssetRecords"
import { BuildingFloorItemUserRecords } from "./BuildingFloorItemUserRecords"

export type BuildingFloorItemProps = {
	floor: ExtendedBuildingFragment["floors"][0]
	building: ExtendedBuildingFragment
}

export const BuildingFloorItem: React.FC<BuildingFloorItemProps> = ({ floor, building }) => {
	const { type } = useDashboardContext()

	return type === DashboardTypes.ASSET_MANAGEMENT ? <BuildingFloorItemAssetRecords floor={floor} buildingId={building._id} /> : <BuildingFloorItemUserRecords floor={floor} building={building} />
}
