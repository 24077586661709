import { Flex } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { ExtendedUserFragment, MeUserFragment } from "../../graphql"
import { Stat } from "../common"

export type VisitorCurrentDetailsProps = {
	user: ExtendedUserFragment | MeUserFragment
}

export const VisitorCurrentDetails: React.FC<VisitorCurrentDetailsProps> = ({ user }) => {
	return (
		<Flex justify="center" align="center" flexWrap="wrap">
			{user.visitorDetails?.visitDate && <Stat label="Visit Date" value={format(new Date(user.visitorDetails?.visitDate), "PP") || "Not available"} />}
			<Stat label="Company Name" value={user.visitorDetails?.companyName || "Not available"} />
			<Stat label="Level" value={user.visitorDetails?.level || "Not available"} />
			<Stat label="Card Number" value={user.visitorDetails?.visitorCardNo || "Not available"} />
			<Stat label="Building" value={user.visitorDetails?.buildingName || "Not available"} />
		</Flex>
	)
}
