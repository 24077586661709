import { VStack } from "@chakra-ui/react"
import React from "react"
import { AssignAssetTagToUserForm } from "../../forms"
import { ExtendedUserFragment } from "../../graphql"

export type AssignAssetTagToUserProps = {
	user: ExtendedUserFragment
}

export const AssignAssetTagToUser: React.FC<AssignAssetTagToUserProps> = ({ user }) => {
	return (
		<VStack w="full" align="stretch" spacing={6} maxW="sm">
			<AssignAssetTagToUserForm user={user} />
		</VStack>
	)
}
