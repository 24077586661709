/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { useCreateAssetTagMutation } from "../graphql"

type CreateAssetTagFormValues = {
	tagHexId: string
}

const validationSchema = yup.object<Record<keyof CreateAssetTagFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	tagHexId: yup.string().required().min(8).label("Tag Hex ID"),
})

export const CreateAssetTagForm: React.FC = () => {
	const initialValues: CreateAssetTagFormValues = {
		tagHexId: "",
	}

	const [{ fetching }, createAssetTag] = useCreateAssetTagMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ tagHexId }: CreateAssetTagFormValues) => {
		const { data, error } = await createAssetTag({ tagHexId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createAssetTag) {
			navigate(`/assetTags/${data.createAssetTag._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateAssetTagFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.tagHexId && formik.errors.tagHexId)}>
					<FormLabel fontWeight="bold">Tag Hex ID</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter Tag ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("tagHexId")} />

					<FormErrorMessage>{formik.errors.tagHexId}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
