import { VStack, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react"
import React from "react"
import { AssignTagForm, AssignTagByDecimalIdForm } from "../../forms"
import { ExtendedUserFragment } from "../../graphql"

export type AssignTagProps = {
	user: ExtendedUserFragment
}

export const AssignTag: React.FC<AssignTagProps> = ({ user }) => {
	return (
		<VStack w="full" align="stretch" spacing={6}>
			<Tabs variant="enclosed-colored">
				<TabList>
					<Tab _selected={{ color: "white", bg: "purple.400" }}>By Device</Tab>
					<Tab _selected={{ color: "white", bg: "green.400" }}>By Tag Id</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<AssignTagForm user={user} />
					</TabPanel>
					<TabPanel>
						<AssignTagByDecimalIdForm user={user} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</VStack>
	)
}
