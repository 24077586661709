import { Button, ButtonGroup, Flex, Text, Link } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedReaderFragment, useMeQuery } from "../../graphql"
import { checkForSomePermissions } from "../../utils"
import { Card, Stat } from "../common"

export type ReaderDetailsProps = {
	reader: ExtendedReaderFragment
	disableActions?: boolean
}

export const ReaderDetails: React.FC<ReaderDetailsProps> = ({ reader, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const canUpdate = useMemo(
		() => checkForSomePermissions(meData?.me, "readers/update-reader")[0],

		[meData]
	)

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/readers/${reader._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(reader.type)} />
				<Stat label="UID" value={reader.readerDecimalId} />
				{reader.assignedToZone && (
					<Stat
						label="Zone"
						value={
							<Link as={NavLink} to={`/zones/${reader.assignedToZoneId}`}>
								{reader.assignedToZone.label.name}
							</Link>
						}
					/>
				)}
				<Stat label="Status" value={reader.isActive ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				<Stat label="Last Heartbeat" value={reader.lastHeartbeatAt ? format(new Date(reader.lastHeartbeatAt), "MMM dd, yyyy p") : "Not available"} />
				<Stat label="Last Connection" value={reader.lastConnectedAt ? format(new Date(reader.lastConnectedAt), "MMM dd, yyyy p") : "Not available"} />

				<Stat label="Since" value={format(new Date(reader.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
