/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ExtendedAssetFragment, UpdateAssetMutationVariables, useUpdateAssetMutation } from "../graphql"

type UpdateAssetFormValues = UpdateAssetMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateAssetFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	name: yup.string().required().label("Name"),
	vendorId: yup.string().label("Vendor"),
})

export type UpdateAssetFormProps = {
	asset: ExtendedAssetFragment
}

export const UpdateAssetForm: React.FC<UpdateAssetFormProps> = ({ asset }) => {
	const initialValues: UpdateAssetFormValues = {
		name: asset.name,
	}

	const [{ fetching }, updateAsset] = useUpdateAssetMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateAssetFormValues) => {
		const { data, error } = await updateAsset({
			assetId: asset._id,
			input: { ...values },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateAsset) {
			navigate(`/assets/${data.updateAsset._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateAssetFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.name && formik.errors.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("name")} />

					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
