import { CategoryScale, Chart as ChartJS, Colors, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js"
import { format } from "date-fns"
import React, { useMemo } from "react"
import { Line } from "react-chartjs-2"
import { useMonthlyUserIdsQuery, UserRoleTypes, useUserTypesQuery } from "../../graphql"

ChartJS.register(Colors, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top" as const,
		},
		title: {
			display: true,
			text: "Users in last month",
		},
	},
}

export const MonthlyUsers: React.FC = () => {
	const [{ data }] = useMonthlyUserIdsQuery()

	const [{ data: userTypesData }] = useUserTypesQuery()

	const chartData = useMemo(
		() => ({
			labels: data?.monthlyUserIds.map((o) => format(new Date(o.date), "MMM dd")),
			datasets:
				userTypesData?.userTypes
					.filter((type) => type.roleType !== UserRoleTypes.Admin)
					.map((type) => ({
						fill: true,
						label: type.label.name,
						data: data?.monthlyUserIds.map((records) => records.typeWiseUsers.find((r) => r.typeId === type._id)?.userIds.length || 0),
					})) || [],
		}),
		[data, userTypesData]
	)

	return <Line options={{ ...options, aspectRatio: 20 / 4 }} data={chartData} width="100%" />
}
