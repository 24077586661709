import { Avatar, Button, ButtonGroup, Heading, HStack, Image, Popover, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure, VStack } from "@chakra-ui/react"
import React from "react"
import { AssetFragment, ExtendedAssetFragment } from "../../graphql"
import { CaptureAssetAvatarModal, UploadAssetAvatarModal } from "../assets"

export type AssetTagProps = {
	asset: ExtendedAssetFragment | AssetFragment
	size?: "md" | "sm"
	allowAvatarChange?: boolean
}

export const AssetTag: React.FC<AssetTagProps> = ({ asset, size = "md" }) => {
	const { isOpen, onClose, getButtonProps } = useDisclosure()
	const { isOpen: isOpen2, onClose: onClose2, getButtonProps: getButtonProps2 } = useDisclosure()

	return (
		<>
			<HStack>
				<Popover placement="end-end">
					<PopoverTrigger>
						<Avatar src={asset.picture?.original.url} cursor="pointer" name={asset.name} rounded="xl" size={size} />
					</PopoverTrigger>
					<PopoverContent>
						<PopoverBody as={VStack} w="full" align="stretch" spacing={6}>
							<Heading fontSize="sm">Avatar</Heading>
							{asset.picture?.original.url && <Image src={asset.picture.original.url} w="sm" />}
							<ButtonGroup orientation="vertical">
								<Button size="sm" {...getButtonProps()}>
									Upload from device
								</Button>
								<Button size="sm" {...getButtonProps2()}>
									Take photo
								</Button>
							</ButtonGroup>
						</PopoverBody>
					</PopoverContent>
				</Popover>

				<VStack align="flex-start" spacing={0}>
					<Heading fontSize={size} color="grayscale.title">
						{asset.name}
					</Heading>
				</VStack>
			</HStack>
			{isOpen && <UploadAssetAvatarModal assetId={asset._id} isOpen={isOpen} onClose={onClose} />}
			{isOpen2 && <CaptureAssetAvatarModal assetId={asset._id} isOpen={isOpen2} onClose={onClose2} />}
		</>
	)
}
