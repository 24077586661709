import { Button, ButtonGroup, Flex, HStack, Link, useDisclosure } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedDepartmentFragment, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type DepartmentDetailsProps = {
	department: ExtendedDepartmentFragment
	disableActions?: boolean
}

export const DepartmentDetails: React.FC<DepartmentDetailsProps> = ({ department, disableActions }) => {
	const { isOpen, getButtonProps } = useDisclosure()

	const [{ data }] = useMeQuery()

	const canAssignHead = useMemo(() => {
		if (department.departmentHeadId) return false

		return checkForPermissions(data?.me, "departments/assign-department-head")[0]
	}, [JSON.stringify(data)])

	const canRemoveHead = useMemo(() => {
		if (!department.departmentHeadId) return false

		return checkForPermissions(data?.me, "departments/remove-department-head")[0]
	}, [JSON.stringify(data)])

	return (
		<Card
			title="Details"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssignHead && (
							<Button as={NavLink} to={`/departments/${department._id}/assignHead`} colorScheme="primary" size="sm">
								Assign Head
							</Button>
						)}
						{canRemoveHead && (
							<Button as={NavLink} to={`/departments/${department._id}/removeHead`} colorScheme="error" size="sm">
								Remove Head
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="flex-start" flexWrap="wrap">
				<Stat
					label="Head"
					value={
						department.departmentHeadId && department.departmentHead ? (
							<Link as={NavLink} to={`/users/${department.departmentHeadId}`}>
								{department.departmentHead.name}
							</Link>
						) : (
							"Not available"
						)
					}
				/>

				<Stat
					label="Zones"
					value={
						<HStack flexWrap="wrap" justify="center" maxW="xs">
							{department.zones.slice(0, isOpen ? undefined : 5).map((o, i) => (
								<Link key={o._id} as={NavLink} to={`/zones/${o._id}`}>
									{o.label.name}
									{i < department.zones.length - 1 && ","}
								</Link>
							))}
							<Button variant="link" size="xs" {...getButtonProps()}>
								...see {isOpen ? "less" : "more"}
							</Button>
						</HStack>
					}
				/>

				<Stat label="Created" value={format(new Date(department.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
