import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { ExtendedDepartmentFragment, useRemoveDepartmentHeadMutation } from "../../graphql"

export type RemoveDepartmentHeadProps = {
	department: ExtendedDepartmentFragment
}

export const RemoveDepartmentHead: React.FC<RemoveDepartmentHeadProps> = ({ department }) => {
	const [{ fetching }, removeDepartmentHead] = useRemoveDepartmentHeadMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleRemoveDepartmentHead = async () => {
		const { error, data } = await removeDepartmentHead({ departmentId: department._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.removeDepartmentHead) {
			toast({ description: "Removed the department head successfully", status: "success" })
			navigate(`/departments/${department._id}`, { replace: true })
			return
		} else {
			toast({ description: "Couldn't remove the department head", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to remove the department head of <strong>{department.label.name}</strong>?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/departments/${department._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="error" isLoading={fetching} onClick={handleRemoveDepartmentHead}>
						Remove
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
