import { AspectRatio, Box, Button, Center, CircularProgress, Heading, HStack, Icon, Image, Modal, ModalBody, ModalContent, ModalOverlay, Select, Spinner, Stack, Text, useOutsideClick, useToast, VStack } from "@chakra-ui/react"
import axios from "axios"
import { differenceInSeconds } from "date-fns"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { RefreshCw } from "react-feather"
import Lottie from "react-lottie"
import Webcam from "react-webcam"
import failedAnimationData from "../../animations/failed.json"
import animationData from "../../animations/LaptopBag.json"
import successAnimationData from "../../animations/success.json"
import {
	ExtendedEnrolledAssetTagFragment,
	ReaderTypes,
	useEnrolledAssetTagWithUserByReaderIdSubscription,
	useFinishLaptopVerificationPictureUploadMutation,
	useLaptopVerificationMutation,
	useReadersByTypeQuery,
	useSignLaptopVerificationPictureUploadMutation,
} from "../../graphql"
import { getFileFormat } from "../../utils"

export const WebcamCapture: React.FC = () => {
	const webcamRef = useRef<Webcam>(null)
	const [imageSrc, setImageSrc] = useState<string | null>(null)

	const successSound = new Audio("/sounds/ping.mp3")
	const errorSound = new Audio("/sounds/error.mp3")

	const playSuccessSound = () => {
		successSound.play()
	}

	const playErrorSound = () => {
		errorSound.play()
	}

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	}

	const successDefaultOptions = {
		loop: false,
		autoplay: true,
		animationData: successAnimationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	}

	const failedDefaultOptions = {
		loop: false,
		autoplay: true,
		animationData: failedAnimationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	}

	const [{ data: readersData, error: readersError, fetching: readersFetching }] = useReadersByTypeQuery({ variables: { type: ReaderTypes.AssetTagEnrolling } })

	const [selectedReaderId, setSelectedReaderId] = useState(localStorage.getItem("@LIGHTHOUSE_TEST/SELECTED_ASSET_TAG_ENROLLING_READER") || "")

	const [enrolledAssetTagData, setEnrolledAssetTagData] = useState<ExtendedEnrolledAssetTagFragment | null | undefined>(null)

	const [{ data, error }] = useEnrolledAssetTagWithUserByReaderIdSubscription({
		variables: { readerId: selectedReaderId },
		pause: !!enrolledAssetTagData || !selectedReaderId,
	})

	const [secondsLeft, setSecondsLeft] = useState(-1)

	const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

	const [, verifyLaptop] = useLaptopVerificationMutation()

	const [isOpen, setIsOpen] = useState(false)

	const toast = useToast()

	const [isFailed, setIsFailed] = useState(false)

	const [, signUpload] = useSignLaptopVerificationPictureUploadMutation()
	const [, finishUpload] = useFinishLaptopVerificationPictureUploadMutation()

	const handleSubmit = async (key: string | undefined) => {
		try {
			const userId = enrolledAssetTagData?.extendedAssetTag?.user?._id

			if (!userId || !key || !enrolledAssetTagData?.readerId) {
				setIsFailed(true)
				setImageSrc(null)
				return
			}

			const { data } = await verifyLaptop({
				userId: userId,
				readerId: enrolledAssetTagData.readerId,
				imageKey: key,
			})

			if (!data?.laptopVerification) {
				setIsFailed(true)
				setImageSrc(null)
				setIsOpen(true)
				playErrorSound()

				setTimeout(() => {
					setEnrolledAssetTagData(null)
					setIsOpen(false)
				}, 2000)

				return
			} else {
				setIsFailed(false)
				setImageSrc(null)
				setIsOpen(true)
				playSuccessSound()
				setTimeout(() => {
					setEnrolledAssetTagData(null)
					setIsOpen(false)
				}, 2000)

				return
			}
		} catch (error) {
			console.error(error)
			setIsFailed(true)
		}
	}

	const onCapturePhoto = useCallback(
		async (base64: string) => {
			try {
				const file = await fetch(base64)
					.then((res) => res.blob())
					.then((blob) => new File([blob], "image.png", { type: "image/png" }))

				if (!file) {
					toast({
						title: "Upload Failed",
						description: "No file selected",
						status: "error",
					})
					return
				}

				if (Math.floor(file.size) > 1e8) {
					setIsFailed(true)
					setImageSrc(null)
					setEnrolledAssetTagData(null)
					toast({
						title: "Upload Failed",
						description: `The file size exceeds the limit of ${1e8 / 1e6} MBs`,
						status: "error",
					})
					return
				}

				const userId = enrolledAssetTagData?.extendedAssetTag?.user?._id

				if (!userId) {
					setIsFailed(true)
					setImageSrc(null)
					setEnrolledAssetTagData(null)
					toast({
						title: "Upload Failed",
						description: "User ID not found",
						status: "error",
					})
					return
				}

				const { data, error } = await signUpload({
					userId,
					format: getFileFormat(file),
				})

				if (error) {
					setIsFailed(true)
					setImageSrc(null)
					setEnrolledAssetTagData(null)
					toast({
						title: "Upload Failed",
						description: error.message,
						status: "error",
					})
					return
				}

				const { key, signedUrl } = data?.signLaptopVerificationPictureUpload || {}

				if (!key || !signedUrl) {
					setIsFailed(true)
					setImageSrc(null)
					setEnrolledAssetTagData(null)
					toast({
						title: "Upload Failed",
						description: "Upload URL could not be generated",
						status: "error",
					})
					return
				}

				const source = axios.CancelToken.source()
				const cancelToken = source.token

				await axios
					.put(signedUrl, file, {
						headers: { "Content-Type": file.type },
						cancelToken,
					})
					.then(async ({ status }) => {
						if (status !== 200) {
							setIsFailed(true)
							setImageSrc(null)
							setEnrolledAssetTagData(null)
							toast({
								title: "Upload Failed",
								description: "The photo could not be uploaded correctly",
								status: "error",
							})
							return
						}

						try {
							const { data, error } = await finishUpload({ input: { key } })

							if (error) {
								setIsFailed(true)
								setImageSrc(null)
								setEnrolledAssetTagData(null)
								toast({
									title: "Upload Failed",
									description: error.message,
									status: "error",
								})
								return
							}

							if (!data) {
								setIsFailed(true)
								setImageSrc(null)
								setEnrolledAssetTagData(null)
								toast({
									title: "Upload Failed",
									description: "Upload could not be finished",
									status: "error",
								})
								return
							}

							handleSubmit(key)
						} catch (error) {
							setIsFailed(true)
							setImageSrc(null)
							setEnrolledAssetTagData(null)
							toast({
								title: "Upload Failed",
								description: error.message,
								status: "error",
							})
						}
					})
					.catch((error) => {
						setIsFailed(true)
						setImageSrc(null)
						setEnrolledAssetTagData(null)
						toast({
							title: "Upload Failed",
							description: error.message,
							status: "error",
						})
					})
			} catch (error) {
				setIsFailed(true)
				setImageSrc(null)
				setEnrolledAssetTagData(null)
				toast({
					title: "Upload Failed",
					description: error.message,
					status: "error",
				})
			}
		},
		[enrolledAssetTagData?.extendedAssetTag?.user?._id, signUpload, finishUpload, toast]
	)

	const capture = () => {
		const imageSrc = webcamRef.current?.getScreenshot({
			width: 300,
			height: 300,
		})

		if (imageSrc) {
			setImageSrc(imageSrc)
			onCapturePhoto(imageSrc)
		} else {
			// Handle the case where imageSrc is undefined (optional)
			setIsFailed(true)
			setImageSrc(null)
			setEnrolledAssetTagData(null)
			toast({
				title: "Image capture failed",
				status: "error",
			})
		}
	}

	useEffect(() => {
		if (!data?.enrolledAssetTagWithUserByReaderId) {
			return
		}

		setImageSrc(null)

		const enrollationTime = new Date()

		if (intervalId) {
			clearInterval(intervalId)
		}

		setEnrolledAssetTagData(data.enrolledAssetTagWithUserByReaderId as ExtendedEnrolledAssetTagFragment)

		setSecondsLeft(2)

		setIntervalId(
			setInterval(() => {
				setSecondsLeft(Math.max(0, 2 - Math.abs(differenceInSeconds(new Date(enrollationTime), new Date()))))
			}, 1000)
		)
	}, [data?.enrolledAssetTagWithUserByReaderId])

	useEffect(() => {
		if (secondsLeft === 0) {
			capture()
		}
	}, [secondsLeft])

	const [facingMode, setFacingMode] = useState("user") // 'user' for front camera, 'environment' for rear camera

	const switchCamera = () => {
		setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"))
	}

	const overlayRef = React.useRef()
	const audioContext = new AudioContext()

	useOutsideClick({
		ref: overlayRef as any,
		handler: () => {
			audioContext.resume()
		},
	})

	const [showCameraSettings, setShowCameraSettings] = useState(false)

	return (
		<VStack w="full">
			<Box visibility={showCameraSettings && !enrolledAssetTagData ? "visible" : "hidden"} w="full" maxW="400px" pos="relative" mt="20">
				<Webcam
					audio={false}
					screenshotFormat="image/jpeg"
					screenshotQuality={0.6}
					style={{
						width: showCameraSettings && !enrolledAssetTagData ? 400 : 0,
						height: showCameraSettings && !enrolledAssetTagData ? 400 : 0,
						objectFit: "cover",
						borderRadius: "10px",
					}}
					videoConstraints={{ facingMode, aspectRatio: 1 }}
				/>
				<Center pos="absolute" top={4} right={4} as={Button} bgColor="grayscale.off-white" rounded="lg" border="2px solid" backgroundColor="gray.100" borderColor="gray.400">
					<Icon as={RefreshCw} fontSize="xl" onClick={switchCamera} />
				</Center>
			</Box>

			<VStack w="full" spacing="10" justifyContent="space-around">
				{error ? (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				) : enrolledAssetTagData ? (
					<VStack pos="relative" w="full" align="center" justify="center" mt="15">
						<HStack w="full" spacing="10" justifyContent="center" alignItems="center">
							<Box w="full" maxW="600px" pos="relative">
								<AspectRatio w="full" ratio={600 / 600}>
									<Image
										src={enrolledAssetTagData.extendedAssetTag?.user?.picture?.original.url}
										alt="User Image"
										style={{
											width: 600,
											height: 600,
											objectFit: "cover",
											borderRadius: "10px",
										}}
									/>
								</AspectRatio>
								<VStack pos="absolute" bottom={0} left={0} right={0} p="4" bgColor="blackAlpha.600" roundedBottom="xl">
									<Heading color="white" fontSize="2xl">
										{enrolledAssetTagData.extendedAssetTag?.user?.name}
									</Heading>
								</VStack>
							</Box>

							{imageSrc ? (
								<AspectRatio w="full" maxW="600px" ratio={600 / 600}>
									<Image
										src={imageSrc}
										alt="Captured"
										style={{
											width: 600,
											height: 600,
											objectFit: "cover",
											borderRadius: "10px",
										}}
									/>
								</AspectRatio>
							) : (
								<Webcam
									audio={false}
									ref={webcamRef}
									screenshotFormat="image/jpeg"
									screenshotQuality={0.6}
									style={{
										width: 600,
										height: 600,
										objectFit: "cover",
										borderRadius: "10px",
									}}
									videoConstraints={{ facingMode, aspectRatio: 1 }}
								/>
							)}
						</HStack>

						<Center pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" bgColor="white" rounded="full" w="8rem" h="8rem">
							{secondsLeft > 0 ? (
								<Box pos="relative">
									<CircularProgress value={(secondsLeft / 2) * 100} color={secondsLeft <= 1 ? "error.500" : "success.500"} size="8rem" thickness="1rem" />
									<Heading pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" fontSize="2xl" color={secondsLeft <= 1 ? "error.500" : "success.500"}>
										{secondsLeft}
									</Heading>
								</Box>
							) : (
								<Spinner h="8rem" w="8rem" thickness="1rem" color="green.500" />
							)}
						</Center>
					</VStack>
				) : (
					<VStack spacing="0" justifyContent="center" alignItems="center">
						{!showCameraSettings && (
							<Box w="400px" h="400px">
								{selectedReaderId && <Lottie options={defaultOptions} height={400} width={400} />}
							</Box>
						)}
						<VStack spacing="10">
							{selectedReaderId ? (
								<Text fontSize="4xl" fontWeight="extrabold">
									Please place your laptop bag
								</Text>
							) : (
								<Text fontSize="4xl" color="red.500" fontWeight="extrabold">
									Please select a reader
								</Text>
							)}

							<HStack>
								<Stack>
									{readersFetching ? (
										<Text>Fetching readers</Text>
									) : readersError ? (
										<VStack>
											<Text>Couldn&apos;t fetch readers</Text>
											<Text>{readersError.message.replace("[GraphQL] ", "")}</Text>
										</VStack>
									) : !readersData?.readersByType.length ? (
										<VStack>
											<Text>Couldn&apos;t fetch readers</Text>
											<Text>Please try again</Text>
										</VStack>
									) : (
										<Select
											variant="filled"
											bgColor="grayscale.input-background"
											placeholder="Select reader"
											_placeholder={{ color: "grayscale.placeholer" }}
											value={selectedReaderId}
											onChange={(e) => {
												setSelectedReaderId(e.target.value)
												localStorage.setItem("@LIGHTHOUSE_TEST/SELECTED_ASSET_TAG_ENROLLING_READER", e.target.value)
											}}
										>
											{readersData.readersByType.map((reader) => (
												<option key={reader._id} style={{ backgroundColor: "transparent" }} value={reader._id}>
													{reader.label.name} - {reader.readerDecimalId}
												</option>
											))}
										</Select>
									)}
								</Stack>
								<Button onClick={() => setShowCameraSettings((prev) => !prev)}>Camera Settings</Button>
							</HStack>
						</VStack>
					</VStack>
				)}
			</VStack>

			<Modal
				isOpen={isOpen}
				onClose={() => {
					setEnrolledAssetTagData(null)
					setImageSrc(null)
				}}
				isCentered
			>
				<ModalOverlay bgColor="rgba(0,0,0,0.7)" backdropFilter="blur(8px)" />
				<ModalContent bg="transparent">
					<ModalBody as={VStack} w="full" spacing="4" p="6">
						<Center w="sm" h="xs" pos="relative">
							{!isFailed ? <Lottie options={successDefaultOptions} height="400" width="400" /> : <Lottie options={failedDefaultOptions} height="400" width="400" />}
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
		</VStack>
	)
}
