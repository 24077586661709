import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { ExtendedDepartmentFragment } from "../../graphql"
import { DepartmentListItem } from "./DepartmentListItem"

export type DepartmentsListProps = {
	departments: ExtendedDepartmentFragment[]
	loadMore: () => void
}

export const DepartmentsList: React.FC<DepartmentsListProps> = ({ departments, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{departments.map((department) => (
				<Fragment key={department._id}>
					<DepartmentListItem key={department._id} department={department} />
					<Divider />
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
