import { Center, chakra, Heading, HStack, LinkBox, LinkOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useAssetLocationRecordsAtZoneQuery, ZoneFragment } from "../../graphql"
import { LiveIndicator } from "../common"

export type ZoneItemAssetRecordsProps = {
	zone: ZoneFragment
}

export const ZoneItemAssetRecords: React.FC<ZoneItemAssetRecordsProps> = ({ zone }) => {
	const [{ data, fetching, error }] = useAssetLocationRecordsAtZoneQuery({ variables: { zoneId: zone._id } })

	const zoneRecords = useMemo(() => {
		if (!data?.assetLocationRecordsAtZone.length) return []

		return data.assetLocationRecordsAtZone.filter((r) => r.zoneId === zone._id)
	}, [data])

	return (
		<VStack
			maxW="3xs"
			as={LinkBox}
			align="flex-start"
			px="6"
			py="4"
			m="1"
			bgColor="grayscale.background"
			transition="background-color, border-color .1s ease-in"
			borderWidth="4px"
			borderStyle="solid"
			borderColor={zoneRecords.length ? "green.200" : "red.200"}
			_hover={{ bgColor: "primary.50", borderColor: "primary.200" }}
			rounded="xl"
			cursor="pointer"
			justify="space-between"
		>
			<LinkOverlay as={NavLink} to={`/zones/${zone._id}`} />
			<Heading fontSize="sm" color="grayscale.title">
				{zone.label.name}
			</Heading>

			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.assetLocationRecordsAtZone ? (
				<VStack align="stretch">
					<Heading fontSize="2xl" color={zoneRecords.length ? "green.500" : "red.500"}>
						{zoneRecords.length || 0}{" "}
						<chakra.span fontSize="sm" fontWeight="normal">
							assets
						</chakra.span>
					</Heading>
					<HStack>
						<LiveIndicator />
					</HStack>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
