import { VStack } from "@chakra-ui/react"
import React from "react"
import { ExtendUserRoleForm } from "../../forms"
import { ExtendedUserRoleFragment } from "../../graphql"

export type ExtendShortTermRoleProps = {
	userRole: ExtendedUserRoleFragment
}

export const ExtendShortTermRole: React.FC<ExtendShortTermRoleProps> = ({ userRole }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<ExtendUserRoleForm userRole={userRole} />
		</VStack>
	)
}
