import { VStack } from "@chakra-ui/react"
import React from "react"
import { CreateBuildingFloorForm } from "../../forms"

export type CreateBuildingFloorProps = {
	buildingId: string
}

export const CreateBuildingFloor: React.FC<CreateBuildingFloorProps> = ({ buildingId }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<CreateBuildingFloorForm buildingId={buildingId} />
		</VStack>
	)
}
