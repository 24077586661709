import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UserSelector } from "../components"
import { ExtendedAlertTargetGroupFragment, UpdateAlertTargetGroupMutationVariables, useUpdateAlertTargetGroupMutation } from "../graphql"

type UpdateAlertTargetGroupFormValues = UpdateAlertTargetGroupMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateAlertTargetGroupFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	userIds: yup.array(yup.string()).required().min(1).label("Users"),
})

export type UpdateAlertTargetGroupFormProps = {
	alertTargetGroup: ExtendedAlertTargetGroupFragment
}

export const UpdateAlertTargetGroupForm: React.FC<UpdateAlertTargetGroupFormProps> = ({ alertTargetGroup }) => {
	const initialValues: UpdateAlertTargetGroupFormValues = {
		label: { name: alertTargetGroup.label.name, description: alertTargetGroup.label.description },
		userIds: alertTargetGroup.userIds,
	}

	const [{ fetching }, updateAlertTargetGroup] = useUpdateAlertTargetGroupMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateAlertTargetGroupFormValues) => {
		const { data, error } = await updateAlertTargetGroup({ alertTargetGroupId: alertTargetGroup._id, input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateAlertTargetGroup) {
			navigate(`/alerts/targetGroups/${data.updateAlertTargetGroup._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateAlertTargetGroupFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
						<FormLabel fontWeight="bold">Name</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

						<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
						<FormLabel fontWeight="bold">Description</FormLabel>

						<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

						<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
					</FormControl>
				</VStack>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.userIds && formik.errors.userIds)}>
						<FormLabel fontWeight="bold">Users</FormLabel>

						<UserSelector value={formik.values.userIds} onUpdate={(userIds) => formik.setFieldValue("userIds", userIds)} />
						<Text fontSize="sm" color="error.400">
							{formik.errors.userIds}
						</Text>
					</FormControl>
					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Update
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
