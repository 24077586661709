export enum IntervalActions {
	SET_INTERVAL = "SET_INTERVAL",
}

export type IntervalState = number

export type IntervalAction = {
	type: IntervalActions
	payload: number
}

export const INTERVALS = {
	"10 Mins.": 10,
	"15 Mins.": 15,
	"30 Mins.": 30,
	"1 Hour": 60,
	"2 Hours": 120,
	"6 Hours": 360,
	"12 Hours": 720,
	"24 Hours": 1440,
}
