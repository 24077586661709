import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { ExtendedAssetFragment, useDeactivateAssetTagOfAssetMutation } from "../../graphql"
import { AssetTag } from "../common"
import { AssetTagDetail } from "./AssetTagDetail"

export type DeactivateAssetTagOfAssetProps = {
	asset: ExtendedAssetFragment
}

export const DeactivateAssetTagOfAsset: React.FC<DeactivateAssetTagOfAssetProps> = ({ asset }) => {
	const [{ fetching }, deactivate] = useDeactivateAssetTagOfAssetMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleDeactivate = async () => {
		const { error, data } = await deactivate({ assetId: asset._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.deactivateAssetTagOfAsset) {
			toast({ description: "Deactivated the asset tag successfully", status: "success" })
			navigate(`/assets/${asset._id}`)
			return
		} else {
			toast({ description: "Couldn't deactivate the tag", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<AssetTag asset={asset} />
			<AssetTagDetail asset={asset} disableActions />

			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to deactivate the tag of {asset.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/assets/${asset._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="error" isLoading={fetching} onClick={handleDeactivate}>
						Deactivate
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
