import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedAssetFragment, TagStatus, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type AssetTagDetailProps = {
	asset: ExtendedAssetFragment
	disableActions?: boolean
}

export const AssetTagDetail: React.FC<AssetTagDetailProps> = ({ asset, disableActions }) => {
	const [{ data }] = useMeQuery()

	const canAssign = useMemo(() => {
		if (asset.tag) return false

		return checkForPermissions(data?.me, "asset-tags/assign-asset-tag")[0]
	}, [data])

	const canFlush = useMemo(() => {
		if (!asset.tag) return false

		return checkForPermissions(data?.me, "asset-tags/flush-asset-tag")[0]
	}, [data])

	const canDeactivate = useMemo(() => {
		if (!asset.tag || asset.tag.status !== TagStatus.Active) return false

		return checkForPermissions(data?.me, "asset-tags/deactivate-asset-tag")[0]
	}, [data])

	const canActivate = useMemo(() => {
		if (!asset.tag || asset.tag.status !== TagStatus.Inactive) return false

		return checkForPermissions(data?.me, "asset-tags/activate-asset-tag")[0]
	}, [data])

	return (
		<Card
			title="Tag"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/assets/${asset._id}/assignTag`} colorScheme="primary" size="sm">
								Assign
							</Button>
						)}
						{canFlush && (
							<Button as={NavLink} to={`/assets/${asset._id}/flushTag`} colorScheme="error" size="sm">
								Flush
							</Button>
						)}
						{canDeactivate && (
							<Button as={NavLink} to={`/assets/${asset._id}/deactivateTag`} colorScheme="error" size="sm">
								Deactivate
							</Button>
						)}
						{canActivate && (
							<Button as={NavLink} to={`/assets/${asset._id}/activateTag`} colorScheme="success" size="sm">
								Activate
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="UID" value={asset.tag?.tagHexId || "Not available"} />
				<Stat label="Status" value={asset.tag?.status === TagStatus.Active ? <Text color="success.600">Active</Text> : asset.tag?.status === TagStatus.Inactive ? <Text color="error.600">Inactive</Text> : "Not available"} />
				<Stat label="Battery Status" value={asset.tag?.batteryStatus || "Not available"} />
				<Stat label="Assigned Since" value={asset.tag?.assignedAt ? format(new Date(asset.tag.assignedAt), "MMM dd, yyyy p") : "Not available"} />
			</Flex>
		</Card>
	)
}
