import { Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { BuildingsByKeywordWithoutPaginationQueryVariables, useBuildingsByKeywordWithoutPaginationQuery } from "../../graphql"

export type BuildingSelectorProps = {
	onUpdate: (buildingIds: string[]) => void
	value: string[]
}

export const BuildingSelector: React.FC<BuildingSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<BuildingsByKeywordWithoutPaginationQueryVariables>({ keyword: "" })

	const [{ data, fetching, error }] = useBuildingsByKeywordWithoutPaginationQuery({ variables })

	const [keyword, setKeyword] = useState("")

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				keyword,
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (buildingId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, buildingId])
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(buildingId), 1)
			onUpdate(_value)
		}
	}

	const isBuildingSelected = useCallback((buildingId: string) => value.includes(buildingId), [value])

	const isAllSelected = useMemo(() => data?.buildingsByKeywordWithoutPagination.map((b) => b._id).every((bid) => value.includes(bid)), [data, value])
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
			</HStack>
			{fetching && !data?.buildingsByKeywordWithoutPagination.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading buildings</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.buildingsByKeywordWithoutPagination.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any building.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked ? onUpdate(data.buildingsByKeywordWithoutPagination.map((r) => r._id)) : onUpdate([])
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>Building</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.buildingsByKeywordWithoutPagination.map((building) => (
								<Tr key={building._id}>
									<Td>
										<Checkbox isChecked={isBuildingSelected(building._id)} onChange={(e) => handleChange(building._id, e.target.checked)} />
									</Td>
									<Td>{building.label.name}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more buildings</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
