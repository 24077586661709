import { Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { Fragment } from "react"

export type UserRestrictedEntriesReportProps = {
	report: any
}

export const UserRestrictedEntriesReport: React.FC<UserRestrictedEntriesReportProps> = ({ report }) => {
	return (
		<VStack w="full" align="stretch" h="full" overflow="auto" spacing={0}>
			<Table size="sm">
				<Thead>
					<Tr>
						<Th>Date</Th>
						<Th>User</Th>
						<Th>Zone</Th>
						<Th>Start</Th>
						<Th>End</Th>
						<Th>Duration</Th>
					</Tr>
				</Thead>
				<Tbody>
					{Object.keys(report).map((key, index) => (
						<Fragment key={key}>
							{report?.[key].map((o: any, i: number) => (
								<Tr key={i} bgColor={index % 2 === 0 ? "transparent" : "gray.100"}>
									<Td>{o.date}</Td>
									<Td>{o.user}</Td>
									<Td>{o.zone}</Td>
									<Td>{o.start}</Td>
									<Td>{o.end}</Td>
									<Td>{o.duration}</Td>
								</Tr>
							))}
						</Fragment>
					))}
				</Tbody>
			</Table>
		</VStack>
	)
}
