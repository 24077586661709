import { VStack } from "@chakra-ui/react"
import React from "react"
import { CreateAssetTagForm } from "../../forms"

export const CreateAssetTag: React.FC = () => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<CreateAssetTagForm />
		</VStack>
	)
}
