import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateUserRoleGroupForm } from "../../forms"
import { ExtendedUserRoleGroupFragment } from "../../graphql"

export type UpdateUserRoleGroupProps = {
	userRoleGroup: ExtendedUserRoleGroupFragment
}

export const UpdateUserRoleGroup: React.FC<UpdateUserRoleGroupProps> = ({ userRoleGroup }) => {
	return (
		<VStack w="full" spacing={6}>
			<UpdateUserRoleGroupForm userRoleGroup={userRoleGroup} />
		</VStack>
	)
}
