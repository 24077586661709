import { format, isSameDay, isSameYear } from "date-fns"

export const transformDateTime = (date: Date) => {
	if (isSameYear(date, new Date())) {
		if (isSameDay(date, new Date())) {
			return format(date, "pp")
		}

		return format(date, "MMM dd, pp")
	}

	return format(date, "MMM dd, yyyy pp")
}
