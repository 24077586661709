import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateBuildingForm } from "../../forms"
import { ExtendedBuildingFragment } from "../../graphql"

export type UpdateBuildingProps = {
	building: ExtendedBuildingFragment
}

export const UpdateBuilding: React.FC<UpdateBuildingProps> = ({ building }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateBuildingForm building={building} />
		</VStack>
	)
}
