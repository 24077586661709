import { Flex, Link } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedTagFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type TagDetailsProps = {
	tag: ExtendedTagFragment
}

export const TagDetails: React.FC<TagDetailsProps> = ({ tag }) => {
	return (
		<Card title="Details">
			<Flex flex="1" justify="center" align="center" flexWrap="wrap">
				{tag.user && (
					<Stat
						label="User"
						value={
							<Link as={NavLink} to={`/users/${tag.user._id}`}>
								{tag.user.name}
							</Link>
						}
					/>
				)}
				<Stat label="UID" value={tag.tagDecimalId} />
				<Stat label="Status" value={tag.status} />
				<Stat label="Battery Status" value={tag.batteryStatus} />
			</Flex>
		</Card>
	)
}
