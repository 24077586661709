import { Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { Fragment } from "react"
import { UserStatus } from "../../../graphql"

export type VisitorsReportProps = {
	report: any
}

export const VisitorsReport: React.FC<VisitorsReportProps> = ({ report }) => {
	return (
		<VStack w="full" align="stretch" h="full" overflow="auto" spacing={0}>
			<Table size="sm">
				<Thead>
					<Tr>
						<Th>Date</Th>
						<Th>User</Th>
						<Th>Status</Th>
						<Th>Type</Th>
						<Th>Start</Th>
						<Th>End</Th>
						<Th>Duration</Th>
						<Th>Visitor Company Name</Th>
						<Th>Level</Th>
						<Th>Visitor Card Number</Th>
						<Th>Building</Th>
					</Tr>
				</Thead>
				<Tbody>
					{Object.keys(report).map((key, index) => (
						<Fragment key={key}>
							{report?.[key].map((o: any, i: number) => (
								<Tr key={i} bgColor={index % 2 === 0 ? "transparent" : "gray.100"}>
									<Td>{o.date}</Td>
									<Td>{o.user}</Td>
									<Td textColor={o.status === UserStatus.Active ? "green.500" : o.status === UserStatus.Inactive ? "red.500" : ""}>{o.status}</Td>
									<Td>{o.type}</Td>
									<Td>{o.start}</Td>
									<Td>{o.end}</Td>
									<Td>{o.duration}</Td>
									<Td>{o.companyName}</Td>
									<Td>{o.level}</Td>
									<Td>{o.visitorCardNo}</Td>
									<Td>{o.buildingName}</Td>
								</Tr>
							))}
						</Fragment>
					))}
				</Tbody>
			</Table>
		</VStack>
	)
}
