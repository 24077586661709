import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllReportsQueryVariables, useAllReportsQuery } from "../../../graphql"
import { ReportsList } from "./ReportsList"

export const AllReports: React.FC = () => {
	const [pagination, setPagination] = useState<AllReportsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useAllReportsQuery({
		variables: { pagination },
	})

	const nextPage = () => {
		if (data?.allReports.hasNextPage && !fetching) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading reports</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.allReports.reports && data.allReports.reports.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.allReports.reports.length || 0} report
						{(data.allReports.reports.length || 0) > 1 ? "s" : ""}
					</Text>

					<ReportsList reports={data.allReports.reports} />

					{data.allReports.hasNextPage && (
						<Button size="sm" variant="link" colorScheme="blackAlpha" color="black" alignSelf="center" onClick={nextPage}>
							Load more
						</Button>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any reports.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
