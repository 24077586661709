import { VStack } from "@chakra-ui/react"
import React from "react"
import { GenerateZoneLocationRecordsReportForm } from "../../../forms"

export const GenerateZoneLocationRecordsReport: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<GenerateZoneLocationRecordsReportForm />
		</VStack>
	)
}
