import { Box, Center, CircularProgress, Heading, Icon, IconButton, Image, Modal, ModalBody, ModalContent, ModalOverlay, useToast, VStack } from "@chakra-ui/react"
import axios, { AxiosProgressEvent } from "axios"
import React, { useCallback, useState } from "react"
import { Check, X } from "react-feather"
import Camera from "react-html5-camera-photo"
import "react-html5-camera-photo/build/css/index.css"
import { useFinishAvatarUploadMutation, useSignAvatarUploadMutation } from "../../graphql"
import { getFileFormat } from "../../utils"

export type CaptureUserAvatarModalProps = {
	userId: string
	isOpen: boolean
	onClose: () => void
}

export const CaptureUserAvatarModal: React.FC<CaptureUserAvatarModalProps> = ({ userId, isOpen, onClose }) => {
	const toast = useToast()
	const [progress, setProgress] = useState(0)
	const [isFinished, setIsFinished] = useState(false)
	const [isFailed, setIsFailed] = useState(false)

	const [, signUpload] = useSignAvatarUploadMutation()
	const [, finishUpload] = useFinishAvatarUploadMutation()

	const [image, setImage] = useState<File | null>()
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const [onCancel, setOnCancel] = useState<() => void>(() => {})

	const onCapturePhoto = useCallback(async (base64: string) => {
		try {
			const file = await fetch(base64)
				.then((res) => res.blob())
				.then((blob) => {
					const file = new File([blob], "image.png", { type: "image/png" })

					return file
				})

			setImage(file)

			if (!file) {
				toast({
					title: "Upload Failed",
					description: "No file selected",
					status: "error",
				})

				setTimeout(onClose, 1000)

				return
			}

			if (Math.floor(file.size) > 1e8) {
				setIsFailed(true)
				toast({
					title: "Upload Failed",
					description: `The file size exceeds the limit of ${1e8 / 1e6} MBs`,
					status: "error",
				})

				setTimeout(onClose, 1000)

				return
			}

			const { data, error } = await signUpload({
				userId,
				format: getFileFormat(file),
			})

			if (error) {
				setIsFailed(true)

				toast({
					title: "Upload Failed",
					description: error.message,
					status: "error",
				})

				setTimeout(onClose, 1000)

				return
			}

			if (!data?.signAvatarUpload) {
				setIsFailed(true)

				toast({
					title: "Upload Failed",
					description: "Upload URL could not be generated",
					status: "error",
				})

				setTimeout(onClose, 1000)

				return
			}

			const {
				signAvatarUpload: { key, signedUrl },
			} = data!

			const source = axios.CancelToken.source()
			const cancelToken = source.token

			setOnCancel(() => () => source.cancel("Cancelled by user"))

			const onUploadProgress = ({ loaded, total = 0 }: AxiosProgressEvent) => {
				setProgress(Math.floor((100 * loaded) / total))
			}

			await axios
				.put(signedUrl, file, {
					headers: { "Content-Type": file.type },
					onUploadProgress,
					cancelToken,
				})
				.then(async ({ status }) => {
					if (status !== 200) {
						setIsFailed(true)

						toast({
							title: "Upload Failed",
							description: "The photo could not be uploaded correctly",
							status: "error",
						})

						setTimeout(onClose, 1000)

						return
					}

					try {
						const { data, error } = await finishUpload({ userId, input: { key } })

						if (error) {
							setIsFailed(true)

							toast({
								title: "Upload Failed",
								description: error.message,
								status: "error",
							})

							setTimeout(onClose, 1000)

							return
						}

						if (!data) {
							setIsFailed(true)

							toast({
								title: "Upload Failed",
								description: "Upload could not be finished",
								status: "error",
							})

							setTimeout(onClose, 1000)

							return
						}

						setTimeout(onClose, 1000)
					} catch (error_1) {
						setIsFailed(true)

						toast({
							title: "Upload Failed",
							description: error_1.message,
							status: "error",
						})

						setTimeout(onClose, 1000)
					}
				})
				.catch((error) => {
					setIsFailed(true)

					toast({
						title: "Upload Failed",
						description: error.message,
						status: "error",
					})

					setTimeout(onClose, 1000)
				})
		} catch (error: any) {
			setIsFailed(true)

			toast({
				title: "Upload Failed",
				description: error.message,
				status: "error",
			})

			setTimeout(onClose, 1000)
		}

		setIsFinished(true)
	}, [])

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalBody as={VStack} w="full" spacing={4} p="6">
					<Heading fontSize="lg">Upload photo</Heading>
					{image ? (
						<Center w="sm" h="xs" pos="relative">
							<Image src={URL.createObjectURL(image)} w="full" />
							<Box pos="absolute" top="0" left="0" right="0" bottom="0" bgColor="blackAlpha.600" backdropFilter={`blur(${100 - progress}px)`}>
								<CircularProgress pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" value={isFailed ? 100 : progress} size="120px" thickness="12px" color={isFailed ? "error.500" : "success.500"} />
								{isFailed ? (
									<Icon pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" as={X} color="error.500" fontSize="6xl" />
								) : isFinished ? (
									<Icon pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" as={Check} color="success.500" fontSize="6xl" />
								) : (
									<IconButton
										aria-label="cancel-upload-btn"
										pos="absolute"
										top="50%"
										left="50%"
										transform="translate(-50%, -50%)"
										colorScheme="error"
										rounded="full"
										onClick={() => {
											onCancel()
											// onClose()
										}}
									>
										<Icon as={X} />
									</IconButton>
								)}
							</Box>
						</Center>
					) : (
						<Camera
							idealFacingMode="user"
							imageCompression={0.6}
							imageType="png"
							isImageMirror
							onCameraError={(error) => {
								toast({
									title: "Camera Error",
									description: error.message,
									status: "error",
								})
							}}
							onTakePhoto={onCapturePhoto}
						/>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
