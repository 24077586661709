import { VStack } from "@chakra-ui/react"
import React from "react"
import { GenerateBuildingLocationRecordsReportForm } from "../../../forms"

export const GenerateBuildingLocationRecordsReport: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<GenerateBuildingLocationRecordsReportForm />
		</VStack>
	)
}
