/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateAssetMutationVariables, useCreateAssetMutation } from "../graphql"

type CreateAssetFormValues = CreateAssetMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateAssetFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	name: yup.string().required().label("Name"),
	vendorId: yup.string().label("Vendor"),
})

const initialValues: CreateAssetFormValues = {
	name: "",
}

export const CreateAssetForm: React.FC = () => {
	const [{ fetching }, createAsset] = useCreateAssetMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateAssetFormValues) => {
		const { data, error } = await createAsset({
			input: { ...values },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createAsset) {
			navigate(`/assets/${data.createAsset._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateAssetFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.name && formik.errors.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("name")} />

					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
