import { Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useZonesByFilterQuery, ZonesByFilterQueryVariables } from "../../graphql"
import { ZoneTypeSelector } from "./ZoneTypeSelector"

export type ZoneSelectorProps = {
	onUpdate: (zoneIds: string[]) => void
	value: string[]
}

export const ZoneSelector: React.FC<ZoneSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<ZonesByFilterQueryVariables>({ filter: { type: undefined, keyword: "" } })

	const [{ data, fetching, error }] = useZonesByFilterQuery({ variables })

	const [keyword, setKeyword] = useState("")

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				filter: {
					...prev.filter,
					keyword,
				},
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (zoneId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, zoneId])
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(zoneId), 1)
			onUpdate(_value)
		}
	}

	const isZoneSelected = useCallback((zoneId: string) => value.includes(zoneId), [value])

	const isAllSelected = useMemo(() => data?.zonesByFilter.map((r) => r._id).every((rid) => value.includes(rid)), [data, value])
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
				<ZoneTypeSelector value={variables.filter.type || ""} onUpdate={(type) => setVariables((prev) => ({ ...prev, filter: { ...prev.filter, type: type || undefined } }))} />
			</HStack>
			{fetching && !data?.zonesByFilter.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading zones</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.zonesByFilter.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any zone.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked ? onUpdate(data.zonesByFilter.map((r) => r._id)) : onUpdate([])
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>Zone</Th>
								<Th>Type</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.zonesByFilter.map((zone) => (
								<Tr key={zone._id}>
									<Td>
										<Checkbox isChecked={isZoneSelected(zone._id)} onChange={(e) => handleChange(zone._id, e.target.checked)} />
									</Td>
									<Td>{zone.label.name}</Td>
									<Td>{zone.type}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more zones</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
