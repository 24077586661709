import { List } from "@chakra-ui/react"
import React from "react"
import { ExtendedAlertFragment } from "../../graphql"
import { Card } from "../common"
import { UserListItem } from "../users/UserListItem"

export type NotifiedUsersProps = {
	alert: ExtendedAlertFragment
}

export const NotifiedUsers: React.FC<NotifiedUsersProps> = ({ alert }) => {
	return (
		<Card title="Notified Users">
			<List spacing={2}>
				{alert.notifiedUsers?.map((user) => (
					<UserListItem key={user._id} user={user} />
				))}
			</List>
		</Card>
	)
}
