import { VStack } from "@chakra-ui/react"
import React from "react"
import { GenerateActiveUsersAtBuildingsReportForm } from "../../../forms"

export const GenerateActiveUsersAtBuildingsReport: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<GenerateActiveUsersAtBuildingsReportForm />
		</VStack>
	)
}
