import { Box, Center, Checkbox, FormControl, HStack, Input, Popover, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DateRange } from "react-date-range"
import { useInViewport } from "react-in-viewport"
import { UsersByDateAndTypeQueryVariables, useUsersByDateAndTypeQuery, useUserTypesQuery } from "../../graphql"
import { UserTag } from "../common"

export type UserByDateAndTypeSelectorProps = {
	onUpdate: (userIds: string[]) => void
	value: string[]
	onSinceUpdate: (sinceDate: Date) => void
	onUntilUpdate: (untilDate: Date) => void
}

export const UserByDateAndTypeSelector: React.FC<UserByDateAndTypeSelectorProps> = ({ value, onUpdate, onSinceUpdate, onUntilUpdate }) => {
	const [{ data: userTypesData }] = useUserTypesQuery()

	const visitor = userTypesData?.userTypes.find((type) => type.label.name === "Visitor")

	const [variables, setVariables] = useState<UsersByDateAndTypeQueryVariables>({ filter: { userTypeId: visitor?._id, keyword: "", since: new Date(), until: new Date() }, pagination: { limit: 20, page: 1 } })

	const [{ data, fetching, error }] = useUsersByDateAndTypeQuery({ variables })

	useEffect(() => {
		if (visitor) {
			setVariables((prev) => ({
				...prev,
				filter: {
					userTypeId: visitor._id,
					since: new Date(),
					until: new Date(),
				},
			}))
		}
	}, [visitor])

	const nextPage = () => {
		if (data?.usersByDateAndType.hasNextPage && !fetching) {
			setVariables((prev) => ({
				...prev,
				pagination: {
					...prev.pagination,
					page: (prev.pagination.page || 0) + 1,
				},
			}))
		}
	}

	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport && data?.usersByDateAndType.hasNextPage && !fetching) {
			nextPage()
		}
	}, [inViewport, data, fetching])

	const [keyword, setKeyword] = useState("")

	const [since, setSince] = useState<Date>(new Date())
	const [until, setUntil] = useState<Date>(new Date())

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				filter: {
					...prev.filter,
					keyword,
					since,
					until,
				},
				pagination: { ...prev.pagination, page: 1 },
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword, since.toString(), until.toString()])

	const handleChange = (userId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, userId])
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(userId), 1)
			onUpdate(_value)
		}
	}

	const handleSinceChange = (startDate: Date) => {
		setSince(startDate)
		onSinceUpdate(startDate)
	}

	const handleUntilChange = (untilDate: Date) => {
		setUntil(untilDate)
		onUntilUpdate(untilDate)
	}

	const isUserSelected = useCallback((userId: string) => value.includes(userId), [value])

	const isAllSelected = useMemo(() => data?.usersByDateAndType.users.map((u) => u._id).every((uid) => value.includes(uid)), [data, value])
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Input variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
				<Popover>
					<PopoverTrigger>
						<FormControl>
							<Input w="2xs" size="sm" variant="filled" bgColor="grayscale.input-background" value={`${format(since, "MMM dd, yyyy")} - ${format(until, "MMM dd, yyyy")}`} isReadOnly />
						</FormControl>
					</PopoverTrigger>
					<PopoverContent w="full" _focus={{ shadow: "none" }}>
						<PopoverBody w="full" p="0">
							<DateRange
								editableDateInputs={true}
								onChange={(item) => {
									if (item?.selection.startDate && item?.selection.endDate) {
										handleSinceChange(item.selection.startDate)
										handleUntilChange(item.selection.endDate)
									}
								}}
								moveRangeOnFirstSelection={false}
								ranges={[{ startDate: since, endDate: until, key: "selection" }]}
								maxDate={new Date()}
							/>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</HStack>
			{fetching && !data?.usersByDateAndType.users.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.usersByDateAndType.users.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any user.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isDisabled={data.usersByDateAndType.hasNextPage}
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked ? onUpdate(data.usersByDateAndType.users.map((u) => u._id)) : onUpdate([])
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>User</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.usersByDateAndType.users.map((user) => (
								<Tr key={user._id}>
									<Td>
										<Checkbox isChecked={isUserSelected(user._id)} onChange={(e) => handleChange(user._id, e.target.checked)} />
									</Td>
									<Td>
										<UserTag user={user} size="sm" />
									</Td>
								</Tr>
							))}
							<Box w="full" h="2" ref={ref as any} />
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more users</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
