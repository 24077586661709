import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedZoneFragment } from "../../graphql"

export type ZoneListItemProps = {
	zone: ExtendedZoneFragment
}

export const ZoneListItem: React.FC<ZoneListItemProps> = ({ zone }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/zones/${zone._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start">
					<Heading fontSize="md" color="grayscale.title">
						{zone.label.name}
					</Heading>
					{zone.building && zone.floor && (
						<Heading fontSize="sm" color="grayscale.label">
							{zone.building.label.name} ({zone.floor.label.name})
						</Heading>
					)}
				</VStack>
				<VStack align="flex-end">
					<Text fontSize="sm">{zone.type}</Text>
				</VStack>
			</HStack>
		</ListItem>
	)
}
