import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AssetStatus, ExtendedAssetFragment, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type AssetDetailsProps = {
	asset: ExtendedAssetFragment
}

export const AssetDetails: React.FC<AssetDetailsProps> = ({ asset }) => {
	const [{ data }] = useMeQuery()

	const canUpdate = useMemo(() => {
		return checkForPermissions(data?.me, "assets/update-asset")[0]
	}, [data])

	const canDeactivate = useMemo(() => {
		if (asset.status === AssetStatus.Inactive) return false
		else {
			return checkForPermissions(data?.me, "assets/deactivate-asset")[0]
		}
	}, [data])

	const canActivate = useMemo(() => {
		if (asset.status === AssetStatus.Active) return false
		else {
			return checkForPermissions(data?.me, "assets/deactivate-asset")[0]
		}
	}, [data])

	return (
		<Card
			title="Details"
			action={
				<ButtonGroup>
					{canUpdate && (
						<Button as={NavLink} to={`/assets/${asset._id}/update`} colorScheme="primary" size="sm">
							Update
						</Button>
					)}
					{canDeactivate && (
						<Button as={NavLink} to={`/assets/${asset._id}/deactivate`} colorScheme="error" size="sm">
							Deactivate
						</Button>
					)}
					{canActivate && (
						<Button as={NavLink} to={`/assets/${asset._id}/activate`} colorScheme="success" size="sm">
							Activate
						</Button>
					)}
				</ButtonGroup>
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Status" value={asset.status === AssetStatus.Active ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				<Stat label="Since" value={format(new Date(asset.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
