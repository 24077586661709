import { Avatar, Button, ButtonGroup, Heading, HStack, Image, Popover, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { ExtendedUserFragment, MeUserFragment, useMeQuery, UserFragment, UserRoleTypes } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { CaptureUserAvatarModal, UploadUserAvatarModal } from "../users"

export type UserTagProps = {
	user: ExtendedUserFragment | UserFragment | MeUserFragment
	size?: "md" | "sm"
	allowAvatarChange?: boolean
}

export const UserTag: React.FC<UserTagProps> = ({ user, size = "md", allowAvatarChange }) => {
	const { isOpen, onClose, getButtonProps } = useDisclosure()
	const { isOpen: isOpen2, onClose: onClose2, getButtonProps: getButtonProps2 } = useDisclosure()

	const [{ data }] = useMeQuery()

	const canChangeAvatar = useMemo(() => {
		if (!allowAvatarChange) return false

		if (user._id === data?.me?._id) return true

		if (!(user as ExtendedUserFragment | MeUserFragment).type?.roleType) {
			return false
		}

		if ((user as ExtendedUserFragment | MeUserFragment).type.roleType === UserRoleTypes.Working) {
			return checkForPermissions(data?.me, "users/update-working-user-avatar")[0]
		}

		if ((user as ExtendedUserFragment | MeUserFragment).type.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(data?.me, "users/update-short-term-user-avatar")[0]
		}

		if ((user as ExtendedUserFragment | MeUserFragment).type.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(data?.me, "users/update-admin-user-avatar")[0]
		}

		return false
	}, [data])

	return (
		<>
			<HStack>
				{canChangeAvatar ? (
					<Popover placement="end-end">
						<PopoverTrigger>
							<Avatar src={user.picture?.original.url} cursor="pointer" name={user.name} rounded="xl" size={size} />
						</PopoverTrigger>
						<PopoverContent>
							<PopoverBody as={VStack} w="full" align="stretch" spacing={6}>
								<Heading fontSize="sm">Avatar</Heading>
								{user.picture?.original.url && <Image src={user.picture.original.url} w="sm" />}
								<ButtonGroup orientation="vertical">
									<Button size="sm" {...getButtonProps()}>
										Upload from device
									</Button>
									<Button size="sm" {...getButtonProps2()}>
										Take photo
									</Button>
								</ButtonGroup>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				) : (
					<Avatar src={user.picture?.original.url} cursor="pointer" name={user.name} rounded="xl" size={size} />
				)}
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize={size} color="grayscale.title">
						{user.name}
					</Heading>
					<Heading fontSize={size === "md" ? "sm" : "xs"} color="grayscale.label">
						@{user.username}
					</Heading>
				</VStack>
			</HStack>
			{isOpen && <UploadUserAvatarModal userId={user._id} isOpen={isOpen} onClose={onClose} />}
			{isOpen2 && <CaptureUserAvatarModal userId={user._id} isOpen={isOpen2} onClose={onClose2} />}
		</>
	)
}
