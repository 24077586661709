import { Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds, format } from "date-fns"
import React from "react"
import { useActiveAttendanceOfUserQuery, UserAttendanceRecordStatus } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Card, LiveIndicator, Stat } from "../common"

export type UserActiveAttendanceProps = {
	userId: string
}

export const UserActiveAttendance: React.FC<UserActiveAttendanceProps> = ({ userId }) => {
	const [{ data, fetching, error }] = useActiveAttendanceOfUserQuery({ variables: { userId } })

	return (
		<Card title="Active attendance" action={<LiveIndicator />} alwaysShowAction>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading active attendance</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeAttendanceOfUser ? (
				<VStack w="full" align="flex-start" p="2">
					<Flex w="full" justify="space-between" align="center">
						<Stat label="Since" value={format(new Date(data.activeAttendanceOfUser.startAt), "MMM dd, yyyy p")} />
						{data.activeAttendanceOfUser.status === UserAttendanceRecordStatus.Final ? <Stat label="Until" value={format(new Date(data.activeAttendanceOfUser.endAt), "MMM dd, yyyy p")} /> : <></>}
						<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(data.activeAttendanceOfUser.endAt), new Date(data.activeAttendanceOfUser.startAt)))} />
					</Flex>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find active attendance
					</Text>
				</Center>
			)}
		</Card>
	)
}
