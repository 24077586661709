import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ExtendedAssetTagFragment } from "../../graphql"
import { AssetTagListItem } from "./AssetTagListItem"

export type AssetTagsListProps = {
	assetTags: ExtendedAssetTagFragment[]

	loadMore?: () => void
}

export const AssetTagsList: React.FC<AssetTagsListProps> = ({ assetTags, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{assetTags.map((assetTag) => (
				<>
					<AssetTagListItem key={assetTag._id} assetTag={assetTag} />
					<Divider />
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
