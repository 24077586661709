/* eslint-disable @typescript-eslint/indent */
import { AspectRatio, Box, Center, HStack, Icon, IconButton, Img, useOutsideClick } from "@chakra-ui/react"
import React, { useEffect, useMemo, useState } from "react"
import { Map, X } from "react-feather"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TileFragment, TileTypes, useTilesQuery } from "../../graphql"

export type TileSelectorProps = {
	tileId?: string
	type?: TileTypes
}

export const TileSelector: React.FC<TileSelectorProps> = ({ tileId, type = TileTypes.Outdoor }) => {
	const { pathname } = useLocation()

	const [{ data }] = useTilesQuery({ variables: { type } })

	const [isOpen, setIsOpen] = useState(false)
	const [selectedTile, setSelectedTile] = useState<TileFragment>()

	useEffect(() => {
		if (pathname.startsWith("/map") && data?.tiles.length && !selectedTile) {
			if (tileId) {
				setSelectedTile(data.tiles.find((tile) => tile._id === tileId))
			} else {
				setSelectedTile(data.tiles[0])
				navigate(`/map/${data.tiles[0]._id}`)
			}
		}
	}, [data, selectedTile, pathname])

	const tilesToShow = useMemo(() => {
		const index = data?.tiles.findIndex((tile) => tile._id === selectedTile?._id)

		if (typeof index !== "number" || index < 0) return data?.tiles.slice(0) || []

		const _tiles = data?.tiles.slice(index + 1).concat(data.tiles.slice(0, index)) || []

		return _tiles
	}, [data, selectedTile])

	const ref = React.useRef()

	useOutsideClick({
		ref: ref as any,
		handler: () => setIsOpen(false),
	})

	const navigate = useNavigate()

	return (
		<Box ref={ref as any} className="tiles-selector" pos="fixed" zIndex={10} top={12} right={4} w={isOpen ? "sm" : 24} transition="width .2s ease-in-out" p={isOpen ? 2 : 0} bgColor="grayscale.off-white" rounded="2xl" overflow="hidden">
			<HStack justify="flex-start" overflow="auto">
				{isOpen && pathname.startsWith("/map") && (
					<IconButton aria-label="close-btn" rounded="full" colorScheme="red" as={NavLink} to="/">
						<Icon as={X} />
					</IconButton>
				)}
				<AspectRatio w="full" minW={isOpen ? 36 : 24} ratio={4 / 3} onClick={() => setIsOpen((prev) => !prev)} border="6px solid" borderColor="primary.400" rounded="2xl" cursor="pointer">
					{selectedTile ? (
						<Img src={selectedTile.picture?.original.url} rounded="lg" />
					) : (
						<Center w="full" bgColor="grayscale.off-white" rounded="lg">
							<Icon as={Map} fontSize="2xl" />
						</Center>
					)}
				</AspectRatio>
				{isOpen ? (
					tilesToShow.map((tile) => (
						<AspectRatio
							key={tile._id}
							w="full"
							minW={isOpen ? 36 : 24}
							ratio={4 / 3}
							rounded="2xl"
							cursor="pointer"
							border="6px solid"
							borderColor="transparent"
							_hover={{ borderColor: "primary.200" }}
							onClick={() => {
								setSelectedTile(tile)
								setIsOpen(false)
								navigate(`/map/${tile._id}`)
							}}
						>
							<Img src={tile.picture?.original.url} rounded="lg" />
						</AspectRatio>
					))
				) : (
					<></>
				)}
			</HStack>
		</Box>
	)
}
