import { VStack } from "@chakra-ui/react"
import React from "react"
import { AssignAssetTagToAssetForm } from "../../forms"
import { ExtendedAssetFragment } from "../../graphql"

export type AssignAssetTagToAssetProps = {
	asset: ExtendedAssetFragment
}

export const AssignAssetTagToAsset: React.FC<AssignAssetTagToAssetProps> = ({ asset }) => {
	return (
		<VStack w="full" align="stretch" spacing={6} maxW="sm">
			<AssignAssetTagToAssetForm asset={asset} />
		</VStack>
	)
}
