import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateZoneGroupForm } from "../../forms"
import { ExtendedZoneGroupFragment } from "../../graphql"

export type UpdateZoneGroupProps = {
	zoneGroup: ExtendedZoneGroupFragment
}

export const UpdateZoneGroup: React.FC<UpdateZoneGroupProps> = ({ zoneGroup }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateZoneGroupForm zoneGroup={zoneGroup} />
		</VStack>
	)
}
