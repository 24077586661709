import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { BuildingFragment, useDeleteBuildingFloorMutation } from "../../graphql"

export type DeleteBuildingFloorProps = {
	buildingId: string
	floor: BuildingFragment["floors"][0]
}

export const DeleteBuildingFloor: React.FC<DeleteBuildingFloorProps> = ({ buildingId, floor }) => {
	const [{ fetching }, deleteBuildingFloor] = useDeleteBuildingFloorMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleDelete = async () => {
		const { error, data } = await deleteBuildingFloor({ buildingId, floorId: floor._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.deleteBuildingFloor) {
			toast({ description: "Deleted the floor successfully", status: "success" })
			navigate(`/zones/buildings/${buildingId}`)
			return
		} else {
			toast({ description: "Couldn't delete the floor", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to delete {floor.label.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/zones/buildings/${buildingId}/floors/${floor._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="error" isLoading={fetching} onClick={handleDelete}>
						Delete
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
