import { Flex, Link, ListItem, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserLocationRecordFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Stat } from "../common"

export type RestrictedEntryListItemProps = {
	userLocationRecord: ExtendedUserLocationRecordFragment
}

export const RestrictedEntryListItem: React.FC<RestrictedEntryListItemProps> = ({ userLocationRecord }) => {
	return (
		<ListItem>
			<VStack w="full" align="flex-start" p="2" bgColor="red.100" rounded="xl">
				<Flex w="full" justify="space-between" align="center">
					<Stat
						label="User"
						value={
							<Link as={NavLink} to={`/users/${userLocationRecord.userId}`}>
								{userLocationRecord.user.name}
							</Link>
						}
					/>
					<Stat
						label="Zone"
						value={
							<Link as={NavLink} to={`/zones/${userLocationRecord.zoneId}`}>
								{userLocationRecord.zone.label.name}
							</Link>
						}
					/>
					<Stat label="Since" value={format(new Date(userLocationRecord.startAt), "MMM dd, yyyy p")} />
					<Stat label="Until" value={format(new Date(userLocationRecord.endAt), "MMM dd, yyyy p")} />
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(userLocationRecord.endAt), new Date(userLocationRecord.startAt)))} />
				</Flex>
			</VStack>
		</ListItem>
	)
}
