/* eslint-disable @typescript-eslint/indent */
import { Box, Center, Divider, Heading, HStack, LinkBox, LinkOverlay, ScaleFade, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { Fragment, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedZoneGroupFragment, useActiveAssetIdsQuery, useZoneGroupsByFilterQuery } from "../../graphql"
import { LiveIndicator } from "../common"

export const ZoneGroupWiseActiveAssets: React.FC = () => {
	const [{ data, fetching, error }] = useActiveAssetIdsQuery()
	const [{ data: zoneGroupsData }] = useZoneGroupsByFilterQuery({ variables: { filter: { keyword: "" } } })

	const processedData = useMemo(() => {
		const _: Record<
			string,
			{
				zoneGroup: ExtendedZoneGroupFragment
				records: string[]
				percent: number
			}
		> = {}

		if (!zoneGroupsData) return []

		zoneGroupsData.zoneGroupsByFilter.forEach((zoneGroup) => {
			if (!data?.activeAssetIds.zoneWiseAssets.length) {
				_[zoneGroup._id] = {
					zoneGroup,
					records: [],
					percent: 0,
				}
			} else {
				_[zoneGroup._id] = {
					zoneGroup,
					records: data.activeAssetIds.zoneWiseAssets.filter((r) => zoneGroup.zoneIds.includes(r.zoneId))?.flatMap((z) => z.assetIds) || [],
					percent: ((data.activeAssetIds.zoneWiseAssets.filter((r) => zoneGroup.zoneIds.includes(r.zoneId))?.flatMap((z) => z.assetIds) || []).length / data.activeAssetIds.assetIds.length) * 100,
				}
			}
		})

		return Object.values(_).sort((a, b) => b.records.length - a.records.length)
	}, [data, zoneGroupsData])

	const benchmark = useMemo(() => processedData?.[0]?.records.length || 0, [processedData])

	const heightPercents = useMemo(() => processedData.map((o) => (o.records.length ? (o.records.length / benchmark) * 100 : 0)), [processedData, benchmark])

	const [hoveredItem, setHoveredItem] = useState(-1)

	return (
		<VStack flex="1" align="stretch" spacing={4} pb="6">
			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Zone groups wise active assets</Heading>
				<LiveIndicator />
			</VStack>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeAssetIds ? (
				<HStack w="full" spacing={0}>
					{processedData.map(({ records, zoneGroup, percent }, i) => (
						<Fragment key={zoneGroup._id}>
							<Divider orientation="vertical" />
							<VStack as={LinkBox} w="full" h="full" align="stretch" cursor="pointer" onMouseEnter={() => setHoveredItem(i)} onMouseLeave={() => setHoveredItem(-1)}>
								<LinkOverlay as={NavLink} to={`/zones/groups/${zoneGroup._id}`} zIndex={1} />
								<VStack w="full" align="stretch" spacing={0} p={6}>
									<Heading fontSize="md" color="primary.400">
										{zoneGroup.label.name}
									</Heading>
									<Heading color="primary.400">{records.length}</Heading>
									<Text fontSize="sm" color="primary.400">
										{Math.round(percent)}%
									</Text>
								</VStack>

								<Box my="auto !important">
									<ScaleFade
										in
										transition={{
											enter: { delay: i / 10 },
											exit: { delay: i / 10 },
										}}
									>
										<Box pos="relative" w="full" h="80px" transform={hoveredItem === i ? "scaleY(1.2)" : ""} transition="transform .2s ease-in-out" cursor="pointer">
											<Box
												pos="absolute"
												top={0}
												left={0}
												right={0}
												bottom={0}
												transform="scaleY(1.5)"
												bgColor="primary.500"
												opacity={0.2}
												clipPath={
													processedData[i + 1]
														? `polygon(0 ${(100 - heightPercents[i]) / 2}%, 100% ${(100 - heightPercents[i + 1]) / 2}%, 100% ${100 - (100 - heightPercents[i + 1]) / 2}%, 0 ${
																100 - (100 - heightPercents[i]) / 2
														  }%)`
														: `polygon(0 ${(100 - heightPercents[i]) / 2}%, 100% ${(100 - heightPercents[i]) / 2}%, 100% ${100 - (100 - heightPercents[i]) / 2}%, 0 ${100 - (100 - heightPercents[i]) / 2}%)`
												}
											/>
											<Box
												pos="absolute"
												top={0}
												left={0}
												right={0}
												bottom={0}
												bgColor="primary.500"
												opacity={records.length / benchmark}
												boxShadow="dark-lg"
												clipPath={
													processedData[i + 1]
														? `polygon(0 ${(100 - heightPercents[i]) / 2}%, 100% ${(100 - heightPercents[i + 1]) / 2}%, 100% ${100 - (100 - heightPercents[i + 1]) / 2}%, 0 ${
																100 - (100 - heightPercents[i]) / 2
														  }%)`
														: `polygon(0 ${(100 - heightPercents[i]) / 2}%, 100% ${(100 - heightPercents[i]) / 2}%, 100% ${100 - (100 - heightPercents[i]) / 2}%, 0 ${100 - (100 - heightPercents[i]) / 2}%)`
												}
											/>
										</Box>
									</ScaleFade>
								</Box>
							</VStack>
							{i === processedData.length - 1 && <Divider orientation="vertical" m="0" />}
						</Fragment>
					))}
				</HStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
