import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { UserGroupsByKeywordQueryVariables, useUserGroupsByKeywordQuery } from "../../graphql"
import { UserGroupsList } from "./UserGroupsList"

export type UserGroupsByKeywordProps = {
	keyword: string
}

export const UserGroupsByKeyword: React.FC<UserGroupsByKeywordProps> = ({ keyword }) => {
	const [filter, setFilter] = useState({ keyword })

	const [pagination, setPagination] = useState<UserGroupsByKeywordQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useUserGroupsByKeywordQuery({
		variables: { ...filter, pagination },
		pause: !fetch,
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ keyword })
		}, 1000)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const nextPage = () => {
		if (data?.userGroupsByKeyword.hasNextPage && !fetching) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.userGroupsByKeyword ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading user groups</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userGroupsByKeyword.userGroups && data.userGroupsByKeyword.userGroups.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.userGroupsByKeyword.userGroups.length || 0} user group
						{(data.userGroupsByKeyword.userGroups.length || 0) > 1 ? "s" : ""}
					</Text>

					<UserGroupsList userGroups={data.userGroupsByKeyword.userGroups} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more user groups</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any user groups.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
