/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AssetSelector } from "../components"
import { GenerateAssetLocationRecordsReportMutationVariables, useGenerateAssetLocationRecordsReportMutation } from "../graphql"

type GenerateAssetLocationRecordsReportFormValues = GenerateAssetLocationRecordsReportMutationVariables["input"]

const validationSchema = yup.object<Record<keyof GenerateAssetLocationRecordsReportFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	assetIds: yup.array().of(yup.string()).min(1).required().label("Assets"),
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	sinceDate: yup.date().required().label("Since Date"),
	untilDate: yup.date().required().label("Until Date"),
	assetsFilter: yup.object({
		keyword: yup.string().nullable(),
		isAllSelected: yup.boolean().nullable(),
	}),
})

const initialValues: GenerateAssetLocationRecordsReportFormValues = {
	assetIds: [],
	label: {
		name: "",
		description: "",
	},
	sinceDate: new Date(),
	untilDate: new Date(),
	assetsFilter: {
		keyword: "",
		isAllSelected: false,
	},
}

export const GenerateAssetLocationRecordsReportForm: React.FC = () => {
	const [{ fetching }, generateAssetLocationRecordsReport] = useGenerateAssetLocationRecordsReportMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: GenerateAssetLocationRecordsReportFormValues) => {
		const { data, error } = await generateAssetLocationRecordsReport({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.generateAssetLocationRecordsReport) {
			navigate(`/reports/past/${data.generateAssetLocationRecordsReport._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<GenerateAssetLocationRecordsReportFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
						<FormLabel fontWeight="bold">Name</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

						<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
						<FormLabel fontWeight="bold">Description</FormLabel>

						<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

						<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
					</FormControl>
					<Popover>
						<PopoverTrigger>
							{formik.values.sinceDate && formik.values.untilDate && (
								<FormControl>
									<FormLabel fontWeight="bold">Date Range</FormLabel>

									<Input variant="filled" bgColor="grayscale.input-background" value={`${format(formik.values.sinceDate, "MMM dd, yyyy")} - ${format(formik.values.untilDate, "MMM dd, yyyy")}`} isReadOnly />
								</FormControl>
							)}
						</PopoverTrigger>
						<PopoverContent w="full" _focus={{ shadow: "none" }}>
							<PopoverBody w="full" p="0">
								<DateRange
									editableDateInputs={true}
									onChange={(item) => {
										if (item?.selection.startDate && item?.selection.endDate) {
											formik.setFieldValue("sinceDate", new Date(item.selection.startDate))
											formik.setFieldValue("untilDate", new Date(item.selection.endDate))
										}
									}}
									moveRangeOnFirstSelection={false}
									ranges={[{ startDate: formik.values.sinceDate, endDate: formik.values.untilDate, key: "selection" }]}
									maxDate={new Date()}
								/>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</VStack>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.assetIds && formik.errors.assetIds)}>
						<FormLabel fontWeight="bold">Assets</FormLabel>

						<AssetSelector
							value={formik.values.assetIds}
							onUpdate={(assetIds, filter) => {
								formik.setFieldValue("assetIds", assetIds)
								formik.setFieldValue("assetsFilter", filter)
							}}
						/>
						<Text fontSize="sm" color="error.400">
							{formik.errors.assetIds}
						</Text>
					</FormControl>
					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Generate
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
