import { Flex, HStack, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { DeviceFragment } from "../../graphql"
import { Stat } from "../common"

export type DeviceListItemProps = {
	device: DeviceFragment
}

export const DeviceListItem: React.FC<DeviceListItemProps> = ({ device }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/devices/${device._id}`} />
			<HStack w="full" justify="space-between" px="2">
				<Flex flex="1" justify="center" align="center" flexWrap="wrap">
					<Stat label="Name" value={device.label.name} />
					<Stat label="UID" value={device.deviceId} />
					<Stat label="Type" value={device.type} />
				</Flex>
			</HStack>
		</ListItem>
	)
}
