import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateReaderForm } from "../../forms"
import { ReaderFragment } from "../../graphql"

export type UpdateReaderProps = {
	reader: ReaderFragment
}

export const UpdateReader: React.FC<UpdateReaderProps> = ({ reader }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateReaderForm reader={reader} />
		</VStack>
	)
}
