import { SubscriptionClient } from "subscriptions-transport-ws"
import { createClient, dedupExchange, fetchExchange, subscriptionExchange } from "urql"
import { GRAPHQL_SERVER_ENDPOINT, GRAPHQL_WS_SERVER_ENDPOINT, SESSION_TOKEN_KEY } from "../constants"
import { cacheExchange } from "./exchanges"

const subscriptionClient = new SubscriptionClient(GRAPHQL_WS_SERVER_ENDPOINT, {
	reconnect: true,
	connectionParams: () => {
		const sessionToken = localStorage.getItem(SESSION_TOKEN_KEY)

		return { sessionToken }
	},
})

export const createUrqlClient = () =>
	createClient({
		url: GRAPHQL_SERVER_ENDPOINT,
		fetchOptions: { credentials: "include" },
		exchanges: [
			dedupExchange,
			cacheExchange,
			fetchExchange,
			subscriptionExchange({
				forwardSubscription: (operation) => subscriptionClient?.request(operation) as any,
			}),
		],
	})
