/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AssignAssetTagToUserMutationVariables, ExtendedUserFragment, useAssignAssetTagToUserMutation } from "../graphql"

type AssignAssetTagToUserFormValues = Pick<AssignAssetTagToUserMutationVariables, "tagHexId">

const validationSchema = yup.object<Record<keyof AssignAssetTagToUserFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	tagHexId: yup.string().required().label("Tag ID"),
})

export type AssignAssetTagToUserFormProps = {
	user: ExtendedUserFragment
}

export const AssignAssetTagToUserForm: React.FC<AssignAssetTagToUserFormProps> = ({ user }) => {
	const initialValues: AssignAssetTagToUserFormValues = {
		tagHexId: "",
	}

	const [{ fetching }, assignAssetTagToUser] = useAssignAssetTagToUserMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ tagHexId }: AssignAssetTagToUserFormValues) => {
		const { data, error } = await assignAssetTagToUser({ userId: user._id, tagHexId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.assignAssetTagToUser) {
			navigate(`/users/${user._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<AssignAssetTagToUserFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.tagHexId && formik.errors.tagHexId)}>
					<FormLabel fontWeight="bold">Tag ID</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter tag ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("tagHexId")} />

					<FormErrorMessage>{formik.errors.tagHexId}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Assign
			</Button>
		</VStack>
	)
}
