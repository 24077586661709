import { Button, ButtonGroup, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedBuildingFragment, useMeQuery } from "../../graphql"
import { checkForSomePermissions } from "../../utils"
import { Card, Stat } from "../common"

export type BuildingDetailsProps = {
	building: ExtendedBuildingFragment
	disableActions?: boolean
}

export const BuildingDetails: React.FC<BuildingDetailsProps> = ({ building, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const canUpdate = useMemo(
		() => checkForSomePermissions(meData?.me, "zones/update-building")[0],

		[meData]
	)

	const canDelete = useMemo(
		() => checkForSomePermissions(meData?.me, "zones/delete-building")[0],

		[meData]
	)

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/zones/buildings/${building._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
						{canDelete && (
							<Button as={NavLink} to={`/zones/buildings/${building._id}/delete`} colorScheme="error" size="sm">
								Delete
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Name" value={building.label.name} />
				{building.label.description && <Stat label="Description" value={building.label.description} />}
				<Stat label="Created" value={format(new Date(building.createdAt), "MMM dd, yyyy")} />
			</Flex>
		</Card>
	)
}
