import { Heading, HStack, Text, VStack } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

export type HeaderProps = {
	title: string
	subHeading?: string | React.ReactNode
}

export const Header: React.FC<HeaderProps & PropsWithChildren> = ({ title, subHeading, children }) => {
	return (
		<HStack flexWrap="wrap" w="full" justifyContent="space-between">
			<VStack align="flex-start">
				<Heading fontSize="xl" color="grayscale.title">
					{title}
				</Heading>
				{subHeading &&
					(typeof subHeading === "string" ? (
						<Text fontSize="sm" color="grayscale.label">
							{subHeading}
						</Text>
					) : (
						subHeading
					))}
			</VStack>
			<HStack>{children}</HStack>
		</HStack>
	)
}
