/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AssignAssetTagToAssetMutationVariables, ExtendedAssetFragment, useAssignAssetTagToAssetMutation } from "../graphql"

type AssignAssetTagToAssetFormValues = Pick<AssignAssetTagToAssetMutationVariables, "tagHexId">

const validationSchema = yup.object<Record<keyof AssignAssetTagToAssetFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	tagHexId: yup.string().required().label("Tag ID"),
})

export type AssignAssetTagToAssetFormProps = {
	asset: ExtendedAssetFragment
}

export const AssignAssetTagToAssetForm: React.FC<AssignAssetTagToAssetFormProps> = ({ asset }) => {
	const initialValues: AssignAssetTagToAssetFormValues = {
		tagHexId: "",
	}

	const [{ fetching }, assignAssetTagToAsset] = useAssignAssetTagToAssetMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ tagHexId }: AssignAssetTagToAssetFormValues) => {
		const { data, error } = await assignAssetTagToAsset({ assetId: asset._id, tagHexId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.assignAssetTagToAsset) {
			navigate(`/assets/${asset._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<AssignAssetTagToAssetFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.tagHexId && formik.errors.tagHexId)}>
					<FormLabel fontWeight="bold">Tag ID</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter tag ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("tagHexId")} />

					<FormErrorMessage>{formik.errors.tagHexId}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Assign
			</Button>
		</VStack>
	)
}
