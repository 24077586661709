/* eslint-disable @typescript-eslint/indent */
import { Center, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { BuildingFragment, useZonesOnBuildingFloorQuery } from "../../graphql"
import { ZoneItem } from "../zoneGroups/ZoneItem"

export type ZoneWiseUsersProps = {
	buildingId: string
	floor: BuildingFragment["floors"][0]
}

export const BuildingFloorZoneWiseUsers: React.FC<ZoneWiseUsersProps> = ({ buildingId, floor }) => {
	const [{ data, fetching, error }] = useZonesOnBuildingFloorQuery({ variables: { floorId: floor._id, buildingId } })

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">All zones</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading zones</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.zonesOnBuildingFloor && data.zonesOnBuildingFloor.length ? (
				<Flex w="full" flexWrap="wrap">
					{data.zonesOnBuildingFloor.map((zone) => (
						<ZoneItem key={zone._id} zone={zone} />
					))}
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any zones.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
