import { Heading, HStack, LinkBox, LinkOverlay, ListItem, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedDepartmentFragment } from "../../graphql"

export type DepartmentListItemProps = {
	department: ExtendedDepartmentFragment
}

export const DepartmentListItem: React.FC<DepartmentListItemProps> = ({ department }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/departments/${department._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{department.label.name}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{department.zones
							.slice(0, 5)
							.map((z) => z.label.name)
							.join(", ")}{" "}
						...
					</Heading>
				</VStack>
			</HStack>
		</ListItem>
	)
}
