import { Accordion, Badge, Button, ButtonGroup, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Bell, Box, File, FilePlus, Grid, HardDrive, Hexagon, Key, Link2, Monitor, Package, PieChart, Server, Shield, Smartphone, Tag, Triangle, Tv, User, Users } from "react-feather"
import { DashboardTypes } from "../../context/Dashboard/types"
import { useAlertsUpdateSubscription, useMeQuery } from "../../graphql"
import { useDashboardContext } from "../../hooks"
import { checkForPermissions } from "../../utils"
import { AccountPopover } from "../common/AccountPopover"
import { SidebarAccordionItem } from "./SidebarAccordionItem"
import { SidebarButton } from "./SidebarButton"

export const Sidebar: React.FC = () => {
	const [newAlerts, setNewAlerts] = useState(new Set<string>())

	const [{ data: alertsData }] = useAlertsUpdateSubscription()

	useEffect(() => {
		if (alertsData?.alertsUpdate) {
			setNewAlerts((prev) => new Set([...prev, alertsData.alertsUpdate._id]))
		}
	}, [alertsData])

	const { type, dispatch, setDashboardType } = useDashboardContext()

	const [{ data }] = useMeQuery()

	return (
		<VStack pos="absolute" top="0" left="0" w="320px" h="100vh" bgColor="white !important" align="stretch" justify="space-between" overflow="hidden" zIndex={10} p="4" backgroundColor="grayscale.background">
			<VStack h="full" align="stretch" overflow="auto" pb="4" spacing={8}>
				{checkForPermissions(data?.me, "dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard", "dashboard/read-asset-management-dashboard")[0] ? (
					<ButtonGroup alignSelf="center" spacing={0}>
						<Button
							size="xs"
							variant={type === "Disaster" ? "solid" : "outline"}
							opacity={type === "Disaster" ? 1 : 0.5}
							colorScheme="primary"
							roundedEnd="none"
							onClick={() => {
								dispatch(setDashboardType(DashboardTypes.DISASTER_MANAGEMENT))
							}}
						>
							Disaster
						</Button>

						<Button
							size="xs"
							variant={type === "Confidentiality" ? "solid" : "outline"}
							opacity={type === "Confidentiality" ? 1 : 0.5}
							colorScheme="primary"
							roundedStart="none"
							roundedEnd="none"
							onClick={() => {
								dispatch(setDashboardType(DashboardTypes.CONFIDENTIALITY))
							}}
						>
							Confidentiality
						</Button>

						<Button
							size="xs"
							variant={type === "Asset" ? "solid" : "outline"}
							opacity={type === "Asset" ? 1 : 0.5}
							colorScheme="primary"
							roundedStart="none"
							onClick={() => {
								dispatch(setDashboardType(DashboardTypes.ASSET_MANAGEMENT))
							}}
						>
							Asset
						</Button>
					</ButtonGroup>
				) : checkForPermissions(data?.me, "dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard")[0] ? (
					<ButtonGroup alignSelf="center" spacing={0}>
						<Button
							size="xs"
							variant={type === "Disaster" ? "solid" : "outline"}
							opacity={type === "Disaster" ? 1 : 0.5}
							colorScheme="primary"
							roundedEnd="none"
							onClick={() => {
								dispatch(setDashboardType(DashboardTypes.DISASTER_MANAGEMENT))
							}}
						>
							Disaster
						</Button>

						<Button
							size="xs"
							variant={type === "Confidentiality" ? "solid" : "outline"}
							opacity={type === "Confidentiality" ? 1 : 0.5}
							colorScheme="primary"
							roundedStart="none"
							onClick={() => {
								dispatch(setDashboardType(DashboardTypes.CONFIDENTIALITY))
							}}
						>
							Confidentiality
						</Button>
					</ButtonGroup>
				) : (
					<></>
				)}

				<VStack h="full" align="stretch" overflow="auto" spacing={8}>
					<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
						<SidebarButton to="/" permissionTags={["dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard", "dashboard/read-asset-management-dashboard"]} permissionsType="or" icon={PieChart}>
							Dashboard
						</SidebarButton>

						<SidebarAccordionItem
							tos={["/users", "/users/create", "/users/types", "/users/types/create"]}
							permissionTags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user", "users/create-short-term-user", "users/create-working-user", "users/create-admin-user"]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={User}>
									Users
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/users" permissionTags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} permissionsType="or">
									Users
								</SidebarButton>
								<SidebarButton to="/users/create" permissionTags={["users/create-short-term-user", "users/create-working-user", "users/create-admin-user"]} permissionsType="or">
									Create User
								</SidebarButton>
								<SidebarAccordionItem
									tos={["/users/types", "/users/types/create"]}
									permissionTags={[
										"user-types/read-short-term-user-type",
										"user-types/read-working-user-type",
										"user-types/read-admin-user-type",
										"user-types/create-short-term-user-type",
										"user-types/create-working-user-type",
										"user-types/create-admin-user-type",
									]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Users}>
											User Types
										</SidebarButton>
									)}
								>
									<SidebarButton to="/users/types" permissionTags={["user-types/read-short-term-user-type", "user-types/read-working-user-type", "user-types/read-admin-user-type"]} permissionsType="or">
										User Types
									</SidebarButton>
									<SidebarButton to="/users/types/create" permissionTags={["user-types/create-short-term-user-type", "user-types/create-working-user-type", "user-types/create-admin-user-type"]} permissionsType="or">
										Create User Type
									</SidebarButton>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/users/groups", "/users/groups/create"]}
									permissionTags={["users/read-user-group", "users/create-user-group"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Users}>
											User Groups
										</SidebarButton>
									)}
								>
									<SidebarButton to="/users/groups" permissionTags={["users/read-user-group"]}>
										User Groups
									</SidebarButton>
									<SidebarButton to="/users/groups/create" permissionTags={["users/create-user-group"]}>
										Create User Group
									</SidebarButton>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/assets", "/assets/create"]}
							permissionTags={["assets/read-asset", "assets/create-asset"]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={Package}>
									Assets
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/assets" permissionTags={["assets/read-asset"]} permissionsType="or">
									Assets
								</SidebarButton>
								<SidebarButton to="/assets/create" permissionTags={["assets/create-asset"]} permissionsType="or">
									Create Asset
								</SidebarButton>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/zones", "/zones/create", "/zones/groups", "/zones/groups/create"]}
							permissionTags={["zones/read-zone"]}
							button={(isActive) => (
								<SidebarButton icon={Hexagon} isActive={isActive} isNested>
									Zones
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/zones" permissionTags={["zones/read-zone"]}>
									Zones
								</SidebarButton>
								<SidebarButton to="/zones/create" permissionTags={["zones/create-zone"]}>
									Create Zone
								</SidebarButton>
								<SidebarAccordionItem
									tos={["/zones/groups", "/zones/groups/create"]}
									permissionTags={["zones/read-zone-group"]}
									button={(isActive) => (
										<SidebarButton icon={Hexagon} isActive={isActive} isNested>
											Zone Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/zones/groups" permissionTags={["zones/read-zone-group"]}>
											Zone Groups
										</SidebarButton>
										<SidebarButton to="/zones/groups/create" permissionTags={["zones/create-zone-group"]}>
											Create Zone Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/zones/buildings", "/zones/buildings/create"]}
									permissionTags={["zones/read-building", "zones/create-building"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton icon={Triangle} isActive={isActive} isNested>
											Buildings
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/zones/buildings" permissionTags={["zones/read-building"]}>
											Buildings
										</SidebarButton>
										<SidebarButton to="/zones/buildings/create" permissionTags={["zones/create-building"]}>
											Create Building
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/reports/past", "/reports/generate"]}
							permissionTags={[
								"reports/create-user-location-records-report",
								"reports/create-user-attendance-report",
								"reports/create-dashboard-report",
								"reports/create-reader-status-logs-report",
								"reports/create-user-restricted-entry-report",
								"reports/create-zone-restricted-entry-report",
								"reports/create-zone-group-restricted-entry-report",
								"reports/create-active-users-report",
								"reports/create-visitor-report",
								"reports/create-asset-location-records-report",
							]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={File}>
									Reports
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/reports/past">Past</SidebarButton>
								<SidebarAccordionItem
									tos={[
										"/reports/generate/locationRecords/users",
										"/reports/generate/locationRecords/zones",
										"/reports/generate/locationRecords/zoneGroups",
										"/reports/generate/locationRecords/buildings",
										"/reports/generate/userAttendance",
										"/reports/generate/dashboard",
										"/reports/generate/readersStatusLogs",
										"/reports/generate/restrictedEntries/users",
										"/reports/generate/restrictedEntries/zones",
										"/reports/generate/restrictedEntries/zoneGroups",
										"/reports/generate/activeUsers",
										"/reports/generate/visitor",
										"/reports/generate/assetMovement",
									]}
									permissionTags={[
										"reports/create-user-location-records-report",
										"reports/create-zone-location-records-report",
										"reports/create-zone-group-location-records-report",
										"reports/create-building-location-records-report",
										"reports/create-user-attendance-report",
										"reports/create-dashboard-report",
										"reports/create-reader-status-logs-report",
										"reports/create-user-restricted-entry-report",
										"reports/create-zone-restricted-entry-report",
										"reports/create-zone-group-restricted-entry-report",
										"reports/create-active-users-report",
										"reports/create-visitor-report",
										"reports/create-asset-location-records-report",
									]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={FilePlus}>
											Generate
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
											<SidebarAccordionItem
												tos={["/reports/generate/locationRecords/users", "/reports/generate/locationRecords/zones", "/reports/generate/locationRecords/zoneGroups", "/reports/generate/locationRecords/buildings"]}
												permissionTags={[
													"reports/create-user-location-records-report",
													"reports/create-zone-location-records-report",
													"reports/create-zone-group-location-records-report",
													"reports/create-building-location-records-report",
												]}
												permissionsType="or"
												button={(isActive) => (
													<SidebarButton isNested isActive={isActive}>
														Location Records
													</SidebarButton>
												)}
											>
												<VStack h="full" align="stretch" overflow="auto" pb="4">
													<SidebarButton to="/reports/generate/locationRecords/users" permissionTags={["reports/create-user-location-records-report"]}>
														User
													</SidebarButton>
													<SidebarButton to="/reports/generate/locationRecords/zones" permissionTags={["reports/create-zone-location-records-report"]}>
														Zones
													</SidebarButton>
													<SidebarButton to="/reports/generate/locationRecords/zoneGroups" permissionTags={["reports/create-zone-group-location-records-report"]}>
														Zone Groups
													</SidebarButton>
													<SidebarButton to="/reports/generate/locationRecords/buildings" permissionTags={["reports/create-building-location-records-report"]}>
														Buildings
													</SidebarButton>
												</VStack>
											</SidebarAccordionItem>
										</Accordion>
										<SidebarButton to="/reports/generate/userAttendance" permissionTags={["reports/create-user-attendance-report"]}>
											User Attendance
										</SidebarButton>
										<SidebarButton to="/reports/generate/dashboard" permissionTags={["reports/create-dashboard-report"]}>
											Dashboard
										</SidebarButton>
										<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
											<SidebarAccordionItem
												tos={["/reports/generate/restrictedEntries/users", "/reports/generate/restrictedEntries/zones", "/reports/generate/restrictedEntries/zoneGroups"]}
												permissionTags={["reports/create-active-users-report"]}
												button={(isActive) => (
													<SidebarButton isNested isActive={isActive}>
														Active Users
													</SidebarButton>
												)}
											>
												<VStack h="full" align="stretch" overflow="auto" pb="4">
													<SidebarButton to="/reports/generate/activeUsersAtZones" permissionTags={["reports/create-active-users-report"]}>
														Zones
													</SidebarButton>
													<SidebarButton to="/reports/generate/activeUsersAtZoneGroups" permissionTags={["reports/create-active-users-report"]}>
														Zone Groups
													</SidebarButton>
													<SidebarButton to="/reports/generate/activeUsersAtBuildings" permissionTags={["reports/create-active-users-report"]}>
														Buildings
													</SidebarButton>
												</VStack>
											</SidebarAccordionItem>
										</Accordion>

										<SidebarButton to="/reports/generate/visitor" permissionTags={["reports/create-visitor-report"]}>
											Visitor
										</SidebarButton>
										<SidebarButton to="/reports/generate/assetMovement" permissionTags={["reports/create-asset-location-records-report"]}>
											Asset Movement
										</SidebarButton>
										<SidebarButton to="/reports/generate/readersStatusLogs" permissionTags={["reports/create-reader-status-logs-report"]}>
											Readers Status Logs
										</SidebarButton>
										<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
											<SidebarAccordionItem
												tos={["/reports/generate/restrictedEntries/users", "/reports/generate/restrictedEntries/zones", "/reports/generate/restrictedEntries/zoneGroups"]}
												permissionTags={["reports/create-user-restricted-entry-report", "reports/create-zone-restricted-entry-report", "reports/create-zone-group-restricted-entry-report"]}
												permissionsType="or"
												button={(isActive) => (
													<SidebarButton isNested isActive={isActive}>
														Restricted Entries
													</SidebarButton>
												)}
											>
												<VStack h="full" align="stretch" overflow="auto" pb="4">
													<SidebarButton to="/reports/generate/restrictedEntries/users" permissionTags={["reports/create-user-restricted-entry-report"]}>
														Users
													</SidebarButton>
													<SidebarButton to="/reports/generate/restrictedEntries/zones" permissionTags={["reports/create-zone-restricted-entry-report"]}>
														Zones
													</SidebarButton>
													<SidebarButton to="/reports/generate/restrictedEntries/zoneGroups" permissionTags={["reports/create-zone-group-restricted-entry-report"]}>
														Zone Groups
													</SidebarButton>
												</VStack>
											</SidebarAccordionItem>
										</Accordion>
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/alerts", "/alerts/flows", "/alerts/flows/create", "/alerts/targetGroups", "/alerts/targetGroups/create"]}
							permissionTags={["alerts/read-alert", "alerts/read-alert-flow", "alerts/create-alert-flow", "alerts/read-alert-target-group", "alerts/create-alert-target-group"]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton
									isNested
									isActive={isActive}
									icon={Bell}
									rightIcon={
										newAlerts.size ? (
											<Badge variant="solid" rounded="full" colorScheme="warning">
												{newAlerts.size}
											</Badge>
										) : undefined
									}
								>
									Alerts
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton
										to="/alerts"
										rightIcon={
											newAlerts.size ? (
												<Badge variant="solid" rounded="full" colorScheme="warning">
													{newAlerts.size}
												</Badge>
											) : undefined
										}
									>
										Alerts
									</SidebarButton>
								</VStack>
								<SidebarAccordionItem
									tos={["/alerts/flows", "/alerts/flows/create"]}
									permissionTags={["alerts/read-alert-flow", "alerts/create-alert-flow"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Link2}>
											Alert Flows
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/alerts/flows" permissionTags={["alerts/read-alert-flow"]}>
											Alert Flows
										</SidebarButton>
										<SidebarButton to="/alerts/flows/create" permissionTags={["alerts/create-alert-flow"]}>
											Create Alert Flow
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/alerts/targetGroups", "/alerts/targetGroups/create"]}
									permissionTags={["alerts/read-alert-target-group", "alerts/create-alert-target-group"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Users}>
											Alert Target Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/alerts/targetGroups" permissionTags={["alerts/read-alert-target-group"]}>
											Alert Target Groups
										</SidebarButton>
										<SidebarButton to="/alerts/targetGroups/create" permissionTags={["alerts/create-alert-target-group"]}>
											Create Alert Target Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/departments", "/departments/create", "/permissionsGroups", "/permissionsGroups/create", "/userRoleGroups", "/userRoleGroups/create"]}
							permissionTags={[
								"departments/read-department",
								"departments/create-department",
								"permissions/read-permissions-tags",
								"permissions/read-permissions-group",
								"permissions/create-permissions-group",
								"user-roles/read-short-term-role-group",
								"user-roles/read-working-role-group",
								"user-roles/create-short-term-role-group",
								"user-roles/create-working-role-group",
								"readers/read-reader",
								"readers/create-reader",
								"devices/read-device",
								"devices/create-device",
								"tags/read-tag",
								"tags/create-tag",
								"services/read-service",
								"services/create-service",
							]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={Box}>
									More
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarAccordionItem
									tos={["/departments", "/departments/create"]}
									permissionTags={["departments/read-department", "departments/create-department"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton icon={Grid} isActive={isActive} isNested>
											Departments
										</SidebarButton>
									)}
								>
									<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
										<SidebarButton to="/departments" permissionTags={["departments/read-department"]}>
											Departments
										</SidebarButton>
										<SidebarButton to="/departments/create" permissionTags={["departments/create-department"]}>
											Create Department
										</SidebarButton>
									</Accordion>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/permissionsGroups", "/permissionsGroups/create"]}
									permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Key}>
											Permissions Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/permissionsGroups" permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
											Permissions Groups
										</SidebarButton>
										<SidebarButton to="/permissionsGroups/create" permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}>
											Create Permissions Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/userRoleGroups", "/userRoleGroups/create"]}
									permissionTags={["user-roles/read-short-term-role-group", "user-roles/read-working-role-group", "user-roles/create-short-term-role-group", "user-roles/create-working-role-group"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Shield}>
											User Role Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/userRoleGroups" permissionTags={["user-roles/read-short-term-role-group", "user-roles/read-working-role-group"]} permissionsType="or">
											User Role Groups
										</SidebarButton>
										<SidebarButton to="/userRoleGroups/create" permissionTags={["user-roles/create-short-term-role-group", "user-roles/create-working-role-group"]} permissionsType="or">
											Create User Role Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/readers", "/readers/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={HardDrive}>
											Readers
										</SidebarButton>
									)}
									permissionTags={["readers/read-reader", "readers/create-reader"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/readers" permissionTags={["readers/read-reader"]}>
											Readers
										</SidebarButton>
										<SidebarButton to="/readers/create" permissionTags={["readers/create-reader"]}>
											Create Reader
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/tags", "/tags/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Tag}>
											Tags
										</SidebarButton>
									)}
									permissionTags={["tags/read-tag", "tags/create-tag"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/tags" permissionTags={["tags/read-tag"]}>
											Tags
										</SidebarButton>
										<SidebarButton to="/tags/create" permissionTags={["tags/create-tag"]}>
											Create Tag
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/assetTags", "/assetTags/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Tag}>
											Asset Tags
										</SidebarButton>
									)}
									permissionTags={["asset-tags/read-asset-tag", "asset-tags/create-asset-tag"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/assetTags" permissionTags={["asset-tags/read-asset-tag"]}>
											Asset Tags
										</SidebarButton>
										<SidebarButton to="/assetTags/create" permissionTags={["asset-tags/create-asset-tag"]}>
											Create Asset Tag
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/devices", "/devices/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Smartphone}>
											Devices
										</SidebarButton>
									)}
									permissionTags={["devices/read-device", "devices/create-device"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/devices" permissionTags={["devices/read-device"]}>
											Devices
										</SidebarButton>
										<SidebarButton to="/devices/create" permissionTags={["devices/create-device"]}>
											Create Device
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/services", "/services/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Server}>
											Services
										</SidebarButton>
									)}
									permissionTags={["services/read-service", "services/create-service"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/services" permissionTags={["services/read-service"]}>
											Services
										</SidebarButton>
										<SidebarButton to="/services/create" permissionTags={["services/create-service"]}>
											Create Service
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarButton to="/tv" permissionTags={["screens/read-tv-screen"]} icon={Tv}>
									TV
								</SidebarButton>
							</Accordion>
						</SidebarAccordionItem>

						{data?.me?.siteId === "65957f437404862120b26919" && (
							<SidebarButton to="/laptopVerification" permissionTags={["laptop-verification/laptop-verification"]} permissionsType="or" icon={Monitor}>
								Laptop Verification
							</SidebarButton>
						)}
					</Accordion>
				</VStack>
			</VStack>
			<AccountPopover />
		</VStack>
	)
}
