import { VStack } from "@chakra-ui/react"
import React from "react"
import { AssignDepartmentHeadForm } from "../../forms"
import { ExtendedDepartmentFragment } from "../../graphql"

export type AssignDepartmentHeadProps = {
	department: ExtendedDepartmentFragment
}

export const AssignDepartmentHead: React.FC<AssignDepartmentHeadProps> = ({ department }) => {
	return (
		<VStack w="full" maxW="2xl" align="stretch" spacing={6}>
			<AssignDepartmentHeadForm department={department} />
		</VStack>
	)
}
