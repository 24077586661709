import { Flex, HStack, Link, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedAssetTagFragment } from "../../graphql"
import { Stat } from "../common"

export type AssetTagListItemProps = {
	assetTag: ExtendedAssetTagFragment
}

export const AssetTagListItem: React.FC<AssetTagListItemProps> = ({ assetTag }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/assetTags/${assetTag._id}`} />
			<HStack w="full" justify="space-between" px="2">
				<Flex flex="1" justify="center" align="center" flexWrap="wrap">
					{assetTag.asset && (
						<Stat
							label="Asset"
							value={
								<Link as={NavLink} to={`/assets/${assetTag.asset._id}`}>
									{assetTag.asset.name}
								</Link>
							}
						/>
					)}
					{assetTag.user && (
						<Stat
							label="User"
							value={
								<Link as={NavLink} to={`/users/${assetTag.user._id}`}>
									{assetTag.user.name}
								</Link>
							}
						/>
					)}
					<Stat label="UID" value={assetTag.tagHexId} />
					<Stat label="Status" value={assetTag.status} />
					<Stat label="Battery Status" value={assetTag.batteryStatus} />
				</Flex>
			</HStack>
		</ListItem>
	)
}
