import { VStack } from "@chakra-ui/react"
import React from "react"
import { ExtendedUserGroupFragment } from "../../graphql"
import { Card } from "../common"
import { UsersList } from "../users/UsersList"

export type UsersOfUserGroupProps = {
	userGroup: ExtendedUserGroupFragment
}

export const UsersOfUserGroup: React.FC<UsersOfUserGroupProps> = ({ userGroup }) => {
	return (
		<Card title="Users">
			<VStack w="full" align="stretch">
				<UsersList users={userGroup.users} />
			</VStack>
		</Card>
	)
}
