import { useContext } from "react"
import { IntervalContext } from "../context/Interval"
import * as intervalActions from "../context/Interval/actions"

export const useIntervalContext = (): [number, (interval: number) => void] => {
	const [interval, dispatch] = useContext(IntervalContext)

	const setInterval = (interval: number) => dispatch(intervalActions.setInterval(interval))

	return [interval, setInterval]
}
