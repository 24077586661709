/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateDeviceMutationVariables, DeviceTypes, useCreateDeviceMutation } from "../graphql"
import startCase from "lodash/startCase"

type CreateDeviceFormValues = CreateDeviceMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateDeviceFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	deviceId: yup.string().required().label("Device ID"),
	type: yup.string().oneOf(Object.values(DeviceTypes)).required().label("Device Type"),
})

export const CreateDeviceForm: React.FC = () => {
	const initialValues: CreateDeviceFormValues = { label: { name: "", description: "" }, deviceId: "", type: DeviceTypes.TagEnrolling }

	const [{ fetching }, createDevice] = useCreateDeviceMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateDeviceFormValues) => {
		const { data, error } = await createDevice({ input: { ...values, deviceId: values.deviceId.toLowerCase() } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createDevice) {
			navigate(`/devices/${data.createDevice._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateDeviceFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.deviceId && formik.errors.deviceId)}>
					<FormLabel fontWeight="bold">Device ID</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter device ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("deviceId")} />

					<FormErrorMessage>{formik.errors.deviceId}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)} isRequired>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("type")}>
						{Object.values(DeviceTypes).map((type) => (
							<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
								{startCase(type)}
							</option>
						))}
					</Select>

					<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
