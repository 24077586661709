import { Heading, HStack, LinkBox, LinkOverlay, ListItem, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertFragment } from "../../graphql"

export type AlertListItemProps = {
	alert: AlertFragment
}

export const AlertListItem: React.FC<AlertListItemProps> = ({ alert }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/alerts/${alert._id}`} />
			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{startCase(alert.type)}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{format(new Date(alert.createdAt), "MMM d, yyyy p")}
					</Heading>
				</VStack>
			</HStack>
		</ListItem>
	)
}
