import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateAssetForm } from "../../forms"
import { ExtendedAssetFragment } from "../../graphql"

export type UpdateAssetProps = {
	asset: ExtendedAssetFragment
}

export const UpdateAsset: React.FC<UpdateAssetProps> = ({ asset }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateAssetForm asset={asset} />
		</VStack>
	)
}
