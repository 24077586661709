export enum DashboardContextActions {
	SET_DASHBOARD_TYPE,
}

export enum DashboardTypes {
	DISASTER_MANAGEMENT = "Disaster",
	CONFIDENTIALITY = "Confidentiality",
	ASSET_MANAGEMENT = "Asset",
}

export type DashboardContextState = {
	type?: DashboardTypes
}

export type DashboardContextAction = { type: DashboardContextActions; payload: DashboardTypes }
