import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserFragment, MeUserFragment, TagStatus, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type AssetTagDetailProps = {
	user: ExtendedUserFragment | MeUserFragment
	disableActions?: boolean
}

export const AssetTagDetail: React.FC<AssetTagDetailProps> = ({ user, disableActions }) => {
	const [{ data }] = useMeQuery()

	const canAssign = useMemo(() => {
		if (user.assetTag) return false

		return checkForPermissions(data?.me, "asset-tags/assign-asset-tag")[0]
	}, [data])

	const canFlush = useMemo(() => {
		if (!user.assetTag) return false

		return checkForPermissions(data?.me, "asset-tags/flush-asset-tag")[0]
	}, [data])

	const canDeactivate = useMemo(() => {
		if (!user.assetTag || user.assetTag.status !== TagStatus.Active) return false

		return checkForPermissions(data?.me, "asset-tags/deactivate-asset-tag")[0]
	}, [data])

	const canActivate = useMemo(() => {
		if (!user.assetTag || user.assetTag.status !== TagStatus.Inactive) return false

		return checkForPermissions(data?.me, "asset-tags/activate-asset-tag")[0]
	}, [data])

	return (
		<Card
			title="Asset Tag"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/users/${user._id}/assignAssetTag`} colorScheme="primary" size="sm">
								Assign
							</Button>
						)}
						{canFlush && (
							<Button as={NavLink} to={`/users/${user._id}/flushAssetTag`} colorScheme="error" size="sm">
								Flush
							</Button>
						)}
						{canDeactivate && (
							<Button as={NavLink} to={`/users/${user._id}/deactivateAssetTag`} colorScheme="error" size="sm">
								Deactivate
							</Button>
						)}
						{canActivate && (
							<Button as={NavLink} to={`/users/${user._id}/activateAssetTag`} colorScheme="success" size="sm">
								Activate
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="UID" value={user.assetTag?.tagHexId || "Not available"} />
				<Stat label="Status" value={user.assetTag?.status === TagStatus.Active ? <Text color="success.600">Active</Text> : user.assetTag?.status === TagStatus.Inactive ? <Text color="error.600">Inactive</Text> : "Not available"} />
				<Stat label="Battery Status" value={user.assetTag?.batteryStatus || "Not available"} />
				<Stat label="Assigned Since" value={user.assetTag?.assignedAt ? format(new Date(user.assetTag.assignedAt), "MMM dd, yyyy p") : "Not available"} />
			</Flex>
		</Card>
	)
}
