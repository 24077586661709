import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Stack, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import startCase from "lodash/startCase"
import React, { FormEvent, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AlertTargetGroupSelector, DepartmentsSelector, UserGroupSelector, UserRoleTypesSelector, UserSelector, UserTypesSelector, ZoneGroupSelector, ZoneSelector } from "../components"
import { AlertSourceInput, AlertSourceTypes, AlertTypes, CreateAlertFlowMutationVariables, useCreateAlertFlowMutation, useMeQuery, UserRoleTypes } from "../graphql"

type CreateAlertFlowFormValues = CreateAlertFlowMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateAlertFlowFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	type: yup.string().oneOf(Object.values(AlertTypes)).required().label("Type"),
	source: yup.object<Record<keyof CreateAlertFlowFormValues["source"], yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
		type: yup.string().oneOf(Object.values(AlertSourceTypes)).nullable().label("Source Type"),
		zoneIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Zones, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Zones"),
		zoneGroupIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.ZoneGroups, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Zone Groups"),
		userIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Users, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Users"),
		userGroupIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.UserGroups, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("User Groups"),
		userTypeIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.UserTypes, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("User Types"),
		userRoleTypes: yup
			.array()
			.when("type", {
				is: AlertSourceTypes.UserRoleTypes,
				then: yup
					.array(yup.string().oneOf(Object.values(UserRoleTypes)))
					.required()
					.min(1),
			})
			.nullable()
			.label("User Role Types"),
		departmentIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Departments, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Departments"),
	}),
	targetGroupIds: yup.array(yup.string()).required().min(1).label("Target Groups"),
})

const initialValues: CreateAlertFlowFormValues = {
	label: { name: "", description: "" },
	type: AlertTypes.InactiveReader,
	source: { type: undefined as any, zoneIds: [], zoneGroupIds: [], userIds: [], userGroupIds: [], userTypeIds: [], userRoleTypes: [], departmentIds: [] },
	targetGroupIds: [],
}

export const CreateAlertFlowForm: React.FC = () => {
	const [{ fetching }, createAlertFlow] = useCreateAlertFlowMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateAlertFlowFormValues) => {
		const { data, error } = await createAlertFlow({ input: { ...values, source: [AlertTypes.InactiveService, AlertTypes.TagBatteryLow].includes(values.type) ? undefined : values.source } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createAlertFlow) {
			navigate(`/alerts/flows/${data.createAlertFlow._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateAlertFlowFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: meQueryData }] = useMeQuery()

	const alertTypes = useMemo(() => {
		if (meQueryData?.me?.type.roleType !== UserRoleTypes.Admin) {
			return Object.values(AlertTypes).filter((o) => o !== AlertTypes.TagBatteryLow && o !== AlertTypes.InactiveService)
		}

		return Object.values(AlertTypes)
	}, [meQueryData?.me])

	const sourceTypes = useMemo(() => {
		if ([AlertTypes.UserRoleExpiry].includes(formik.values.type)) {
			if (
				formik.values.source?.type !== AlertSourceTypes.Users &&
				formik.values.source?.type !== AlertSourceTypes.UserGroups &&
				formik.values.source?.type !== AlertSourceTypes.UserTypes &&
				formik.values.source?.type !== AlertSourceTypes.UserRoleTypes &&
				formik.values.source?.type !== AlertSourceTypes.Departments
			) {
				formik.setFieldValue("source.type", AlertSourceTypes.Users)
			}

			return [AlertSourceTypes.Users, AlertSourceTypes.UserGroups, AlertSourceTypes.UserTypes, AlertSourceTypes.UserRoleTypes, AlertSourceTypes.Departments]
		} else if ([AlertTypes.InactiveReader, AlertTypes.RestrictedEntry].includes(formik.values.type)) {
			if (formik.values.source?.type !== AlertSourceTypes.Zones && formik.values.source?.type !== AlertSourceTypes.ZoneGroups) {
				formik.setFieldValue("source.type", AlertSourceTypes.Zones)
			}

			return [AlertSourceTypes.Zones, AlertSourceTypes.ZoneGroups]
		} else if ([AlertTypes.InactiveService, AlertTypes.TagBatteryLow, AlertTypes.DailyStatisticsReport].includes(formik.values.type)) {
			formik.setFieldValue("source", undefined, false)
		}

		return []
	}, [formik.values.type])

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }}>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
						<FormLabel fontWeight="bold">Name</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

						<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
						<FormLabel fontWeight="bold">Description</FormLabel>

						<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

						<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)} isRequired>
						<FormLabel fontWeight="bold">Type</FormLabel>

						<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("type")}>
							{alertTypes.map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{startCase(type)}
								</option>
							))}
						</Select>

						<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
					</FormControl>

					{formik.values.type !== AlertTypes.TagBatteryLow && formik.values.type !== AlertTypes.InactiveService && formik.values.type !== AlertTypes.DailyStatisticsReport && (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.type && (formik.errors.source as AlertSourceInput)?.type)} isRequired>
							<FormLabel fontWeight="bold">Source Type</FormLabel>

							<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Source Type" {...formik.getFieldProps("source.type")}>
								{Object.values(sourceTypes).map((type) => (
									<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
										{startCase(type)}
									</option>
								))}
							</Select>

							<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
						</FormControl>
					)}

					{formik.values.source?.type === AlertSourceTypes.Zones ? (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.zoneIds && (formik.errors.source as AlertSourceInput)?.zoneIds)}>
							<FormLabel fontWeight="bold">Source Zones</FormLabel>

							<ZoneSelector value={formik.values.source.zoneIds || []} onUpdate={(zoneIds) => formik.setFieldValue("source.zoneIds", zoneIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.zoneIds}</FormErrorMessage>
						</FormControl>
					) : formik.values.source?.type === AlertSourceTypes.ZoneGroups ? (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.zoneGroupIds && (formik.errors.source as AlertSourceInput)?.zoneGroupIds)}>
							<FormLabel fontWeight="bold">Source Zone Groups</FormLabel>

							<ZoneGroupSelector value={formik.values.source.zoneGroupIds || []} onUpdate={(zoneGroupIds) => formik.setFieldValue("source.zoneGroupIds", zoneGroupIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.zoneGroupIds}</FormErrorMessage>
						</FormControl>
					) : formik.values.source?.type === AlertSourceTypes.Users ? (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userIds && (formik.errors.source as AlertSourceInput)?.userIds)}>
							<FormLabel fontWeight="bold">Source Users</FormLabel>

							<UserSelector value={formik.values.source.userIds || []} onUpdate={(userIds) => formik.setFieldValue("source.userIds", userIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userIds}</FormErrorMessage>
						</FormControl>
					) : formik.values.source?.type === AlertSourceTypes.UserGroups ? (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userGroupIds && (formik.errors.source as AlertSourceInput)?.userGroupIds)}>
							<FormLabel fontWeight="bold">Source User Groups</FormLabel>

							<UserGroupSelector value={formik.values.source.userGroupIds || []} onUpdate={(userGroupIds) => formik.setFieldValue("source.userGroupIds", userGroupIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userGroupIds}</FormErrorMessage>
						</FormControl>
					) : formik.values.source?.type === AlertSourceTypes.UserTypes ? (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userTypeIds && (formik.errors.source as AlertSourceInput)?.userTypeIds)}>
							<FormLabel fontWeight="bold">Source User Types</FormLabel>

							<UserTypesSelector value={formik.values.source.userTypeIds || []} onUpdate={(userTypeIds) => formik.setFieldValue("source.userTypeIds", userTypeIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userTypeIds}</FormErrorMessage>
						</FormControl>
					) : formik.values.source?.type === AlertSourceTypes.UserRoleTypes ? (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userRoleTypes && (formik.errors.source as AlertSourceInput)?.userRoleTypes)}>
							<FormLabel fontWeight="bold">Source User Role Types</FormLabel>

							<UserRoleTypesSelector value={formik.values.source.userRoleTypes || []} onUpdate={(userRoleTypes) => formik.setFieldValue("source.userRoleTypes", userRoleTypes)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userRoleTypes}</FormErrorMessage>
						</FormControl>
					) : (
						formik.values.source?.type === AlertSourceTypes.Departments && (
							<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.departmentIds && (formik.errors.source as AlertSourceInput)?.departmentIds)}>
								<FormLabel fontWeight="bold">Source Departments</FormLabel>

								<DepartmentsSelector value={formik.values.source.departmentIds || []} onUpdate={(departmentIds) => formik.setFieldValue("source.departmentIds", departmentIds)} />

								<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.departmentIds}</FormErrorMessage>
							</FormControl>
						)
					)}
				</VStack>
				<VStack w="full" align="stretch">
					<FormControl isInvalid={Boolean(formik.touched.targetGroupIds && formik.errors.targetGroupIds)}>
						<FormLabel fontWeight="bold">Target Groups</FormLabel>

						<AlertTargetGroupSelector value={formik.values.targetGroupIds || []} onUpdate={(targetGroupIds) => formik.setFieldValue("targetGroupIds", targetGroupIds)} />

						<FormErrorMessage>{formik.errors.targetGroupIds}</FormErrorMessage>
					</FormControl>
					<Button type="submit" colorScheme="primary" isLoading={fetching}>
						Create
					</Button>
				</VStack>
			</Stack>
		</VStack>
	)
}
