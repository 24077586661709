import { Heading, HStack, LinkBox, LinkOverlay, ListItem, Tag, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ReportFragment, ReportStatus } from "../../../graphql"
import startCase from "lodash/startCase"

export type ReportListItemProps = {
	report: ReportFragment
}

export const ReportListItem: React.FC<ReportListItemProps> = ({ report }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/reports/past/${report._id}`} />

			<HStack justifyContent="space-between" p="2">
				<VStack align="flex-start" spacing={0}>
					<Heading fontSize="md" color="grayscale.title">
						{report.label.name}
					</Heading>
					<Heading fontSize="sm" color="grayscale.label">
						{format(new Date(report.createdAt), "MMM dd, yyyy p")}
					</Heading>
				</VStack>
				<VStack align="flex-end">
					<Text fontSize="sm">{startCase(report.type)}</Text>
					<Tag colorScheme={report.status === ReportStatus.Generated ? "green" : report.status === ReportStatus.Failed ? "red" : "yellow"}>{report.status}</Tag>
				</VStack>
			</HStack>
		</ListItem>
	)
}
