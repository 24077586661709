import { HStack, LinkBox, LinkOverlay, ListItem, Tag, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { AssetFragment, AssetStatus, ExtendedAssetFragment } from "../../graphql"
import { AssetTag } from "../common"

export type AssetListItemProps = {
	asset: ExtendedAssetFragment | AssetFragment
	onAssetClick?: (asset: ExtendedAssetFragment | AssetFragment) => void
}

export const AssetListItem: React.FC<AssetListItemProps> = ({ asset, onAssetClick }) => {
	return (
		<ListItem as={onAssetClick ? "li" : LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" onClick={() => (onAssetClick ? onAssetClick(asset) : undefined)}>
			{!onAssetClick && <LinkOverlay as={NavLink} to={`/assets/${asset._id}`} />}
			<HStack justifyContent="space-between" p="2">
				<AssetTag asset={asset} />

				<VStack align="flex-end">
					<Tag size="sm" variant="outline" colorScheme={asset.status === AssetStatus.Active ? "success" : "error"}>
						{asset.status}
					</Tag>
					<Text fontSize="xs" color="grayscale.label">
						{format(new Date(asset.createdAt), "MMM dd, yyyy p")}
					</Text>
				</VStack>
			</HStack>
		</ListItem>
	)
}
