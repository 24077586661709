/* eslint-disable @typescript-eslint/indent */
import { Button, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedBuildingFragment, useMeQuery } from "../../graphql"
import { checkForSomePermissions } from "../../utils"
import { BuildingFloorItem } from "./BuildingFloorItem"

export type BuildingFloorWiseUsersProps = {
	building: ExtendedBuildingFragment
}

export const BuildingFloorWiseUsers: React.FC<BuildingFloorWiseUsersProps> = ({ building }) => {
	const [{ data: meData }] = useMeQuery()

	const canAddFloor = useMemo(
		() => checkForSomePermissions(meData?.me, "zones/create-building-floor")[0],

		[meData]
	)

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<HStack w="full" justify="space-between" maxW="xl">
				<Heading fontSize="sm">All floors</Heading>
				{canAddFloor && (
					<Button as={NavLink} to={`/zones/buildings/${building._id}/addFloor`} colorScheme="primary" size="sm">
						Add
					</Button>
				)}
			</HStack>
			{building.floors.length ? (
				<Flex w="full" flexWrap="wrap">
					{building.floors.map((floor) => (
						<BuildingFloorItem key={floor._id} floor={floor} building={building} />
					))}
				</Flex>
			) : (
				<Text fontSize="sm" color="grayscale.label">
					No floors added
				</Text>
			)}
		</VStack>
	)
}
