import { List } from "@chakra-ui/react"
import React from "react"
import { ExtendedAlertFragment } from "../../graphql"
import { AlertFlowListItem } from "../alertFlows/AlertFlowListItem"
import { Card } from "../common"

export type AlertFlowsOfAlertProps = {
	alert: ExtendedAlertFragment
}

export const AlertFlowsOfAlert: React.FC<AlertFlowsOfAlertProps> = ({ alert }) => {
	return (
		<Card title="Alert Flows">
			<List spacing={2}>
				{alert.alertFlows.map((flow) => (
					<AlertFlowListItem key={flow._id} alertFlow={flow} />
				))}
			</List>
		</Card>
	)
}
