import { VStack } from "@chakra-ui/react"
import React from "react"
import { CreateUserRoleGroupForm } from "../../forms"

export const CreateUserRoleGroup: React.FC = () => {
	return (
		<VStack w="full" spacing={6}>
			<CreateUserRoleGroupForm />
		</VStack>
	)
}
