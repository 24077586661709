import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateBuildingFloorForm } from "../../forms"
import { BuildingFragment } from "../../graphql"

export type UpdateBuildingFloorProps = {
	buildingId: string
	floor: BuildingFragment["floors"][0]
}

export const UpdateBuildingFloor: React.FC<UpdateBuildingFloorProps> = ({ buildingId, floor }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateBuildingFloorForm buildingId={buildingId} floor={floor} />
		</VStack>
	)
}
