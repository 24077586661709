import { Button, ButtonGroup, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ExtendedZoneFragment, useMeQuery } from "../../graphql"
import { checkForSomePermissions } from "../../utils"
import { Card, Stat } from "../common"

export type ZoneDetailsProps = {
	zone: ExtendedZoneFragment
	disableActions?: boolean
}

export const ZoneDetails: React.FC<ZoneDetailsProps> = ({ zone, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const canUpdate = useMemo(
		() => checkForSomePermissions(meData?.me, "zones/update-zone")[0],

		[meData]
	)

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/zones/${zone._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={zone.type} />
				{zone.building && <Stat label="Building" value={zone.building.label.name} />}
				{zone.floor && <Stat label="Floor" value={zone.floor.label.name} />}
				<Stat label="Since" value={format(new Date(zone.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
