import { Divider, List } from "@chakra-ui/react"
import React, { Fragment } from "react"
import { ServiceFragment } from "../../graphql"
import { ServiceListItem } from "./ServiceListItem"

export type ServicesListProps = {
	services: ServiceFragment[]
}

export const ServicesList: React.FC<ServicesListProps> = ({ services }) => {
	return (
		<List spacing={2}>
			{services.map((service) => (
				<Fragment key={service._id}>
					<ServiceListItem key={service._id} service={service} />
					<Divider />
				</Fragment>
			))}
		</List>
	)
}
