import { Button, ButtonGroup, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { DeviceFragment, useMeQuery } from "../../graphql"
import { checkForSomePermissions } from "../../utils"
import { Card, Stat } from "../common"

export type DeviceDetailsProps = {
	device: DeviceFragment
	disableActions?: boolean
}

export const DeviceDetails: React.FC<DeviceDetailsProps> = ({ device, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const canUpdate = useMemo(
		() => checkForSomePermissions(meData?.me, "devices/update-device")[0],

		[meData]
	)

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/devices/${device._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Type" value={startCase(device.type)} />
				<Stat label="UID" value={device.deviceId} />
				<Stat label="Since" value={format(new Date(device.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
