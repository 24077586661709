import { Center, Flex, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React, { useState } from "react"
import { AssignUserRoleForm } from "../../forms"
import { ExtendedUserFragment, useUserRoleGroupByIdQuery } from "../../graphql"
import { Card, Stat } from "../common"

export type AssignUserRoleProps = {
	user: ExtendedUserFragment
}

export const AssignUserRole: React.FC<AssignUserRoleProps> = ({ user }) => {
	const [selectedUserRoleGroupId, setSelectedUserRoleGroupId] = useState("")

	const [{ data, fetching, error }] = useUserRoleGroupByIdQuery({ variables: { userRoleGroupId: selectedUserRoleGroupId }, pause: !selectedUserRoleGroupId })

	return (
		<VStack w="full" spacing={6}>
			<Stack w="full" direction={{ base: "column", xl: "row" }} spacing={6}>
				<VStack w="full" maxW="sm">
					<AssignUserRoleForm user={user} onSelectGroup={setSelectedUserRoleGroupId} />
				</VStack>
				{selectedUserRoleGroupId && (
					<Card title="Selected Work Role" maxW="xl">
						{fetching ? (
							<Center w="full" py="4">
								<VStack w="full" color="grayscale.label">
									<Text fontSize="sm">Loading details</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : error ? (
							<Center py="4">
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									{error.message.replace("[GraphQL] ", "")}
								</Text>
							</Center>
						) : data?.userRoleGroupById ? (
							<Flex justify="center" align="center" flexWrap="wrap">
								<Stat label="User Role Type" value={startCase(data?.userRoleGroupById.type)} />
								<Stat label="User Role Group" value={data?.userRoleGroupById.label.name} />
								<Stat label="Department" value={data?.userRoleGroupById.department.label.name} />
								{data?.userRoleGroupById.allowedZones?.length ? (
									<Stat label="Allowed Zones" value={data?.userRoleGroupById.allowedZones.map((zone) => zone.label.name).join(", ")} />
								) : data?.userRoleGroupById.allowedZoneGroups?.length ? (
									<Stat label="Allowed Zone Groups" value={data?.userRoleGroupById.allowedZoneGroups.map((group) => group.label.name).join(", ")} />
								) : (
									<></>
								)}
							</Flex>
						) : (
							<Center py="4">
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									Couldn&apos;t find details
								</Text>
							</Center>
						)}
					</Card>
				)}
			</Stack>
		</VStack>
	)
}
