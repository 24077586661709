import { Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { BuildingFragment, UpdateBuildingMutationVariables, useUpdateBuildingFloorMutation } from "../graphql"

type UpdateBuildingFloorFormValues = UpdateBuildingMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateBuildingFloorFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
})

export type UpdateBuildingFloorFormProps = {
	buildingId: string
	floor: BuildingFragment["floors"][0]
}

export const UpdateBuildingFloorForm: React.FC<UpdateBuildingFloorFormProps> = ({ buildingId, floor }) => {
	const initialValues: UpdateBuildingFloorFormValues = {
		label: { name: floor.label.name, description: floor.label.description },
	}

	const [{ fetching }, updateBuildingFloor] = useUpdateBuildingFloorMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateBuildingFloorFormValues) => {
		const { data, error } = await updateBuildingFloor({ buildingId, floorId: floor._id, input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateBuildingFloor) {
			navigate(`/zones/buildings/${data.updateBuildingFloor._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateBuildingFloorFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
