import { Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ExtendedBuildingFragment, UpdateBuildingMutationVariables, useUpdateBuildingMutation } from "../graphql"

type UpdateBuildingFormValues = UpdateBuildingMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateBuildingFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
})

export type UpdateBuildingFormProps = {
	building: ExtendedBuildingFragment
}

export const UpdateBuildingForm: React.FC<UpdateBuildingFormProps> = ({ building }) => {
	const initialValues: UpdateBuildingFormValues = {
		label: { name: building.label.name, description: building.label.description },
	}

	const [{ fetching }, updateBuilding] = useUpdateBuildingMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateBuildingFormValues) => {
		const { data, error } = await updateBuilding({ buildingId: building._id, input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateBuilding) {
			navigate(`/zones/buildings/${data.updateBuilding._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateBuildingFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
