import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { TagBatteryStatus, AssetTagsByFilterQueryVariables, TagStatus, useAssetTagsByFilterQuery } from "../../graphql"
import { AssetTagsList } from "./AssetTagsList"

export type AssetTagsByFilterProps = {
	keyword?: string
	status?: TagStatus
	batteryStatus?: TagBatteryStatus
}

export const AssetTagsByFilter: React.FC<AssetTagsByFilterProps> = ({ keyword, status, batteryStatus }) => {
	const [filter, setFilter] = useState({ keyword, status, batteryStatus })

	const [pagination, setPagination] = useState<AssetTagsByFilterQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useAssetTagsByFilterQuery({
		variables: { filter, pagination },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ keyword, status, batteryStatus })
		}, 1000)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword, status, batteryStatus])

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.assetTagsByFilter.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.assetTagsByFilter ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading asset tags</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.assetTagsByFilter.assetTags && data.assetTagsByFilter.assetTags.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.assetTagsByFilter.assetTags.length || 0} asset tag
						{(data.assetTagsByFilter.assetTags.length || 0) > 1 ? "s" : ""}
					</Text>

					<AssetTagsList assetTags={data.assetTagsByFilter.assetTags} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more asset tags</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any asset tags.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
